<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container">
      <a class="navbar-brand" href="#">
      <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo p-0" routerLink="/">
          <img src="./assets/images/theracers.png" alt="">
        </a>
        <a class="navbar-brand brand-logo-mini p-0" routerLink="/">
          <img src="./assets/images/theracers.png" alt="" style="margin-left:15px">
        </a>
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="toggleOffcanvas()" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" title="{{ 'SITE.HOME' | translate }}" routerLink="/"><span class="fa fa-home"></span> <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/organizers">{{ 'SITE.LEAGUES' | translate }} <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/leagues">{{ 'SITE.CHAMPIONSHIPS' | translate }} <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/races">{{ 'SITE.RACES' | translate }} <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/drivers">{{ 'SITE.DRIVERS' | translate }} <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/teams">{{ 'SITE.TEAMS' | translate }} <span class="sr-only">(current)</span></a>
        <!-- <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/ranking">{{ 'SITE.RANKING' | translate }} <span class="sr-only">(current)</span></a> -->
        <!-- <a class="btn btn-outline-light ml-2" target="_blank" (click)="toggleOffcanvas()" href="https://store.theracersesports.com">{{ 'SITE.ECOMMERCE' | translate }} <span class="sr-only">(current)</span></a> -->
        <!-- <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/how-can-i-drive">{{ 'SITE.START-DRIVING' | translate }} <span class="sr-only">(current)</span></a> -->
        <!-- <a class="nav-item nav-link" href="#">Features</a>
        <a class="nav-item nav-link" href="#">Pricing</a>
        <a class="nav-item nav-link" href="#" tabindex="-1" aria-disabled="true">Disabled</a> -->
      </div>
    </div>
    <div class="navbar-right collapse navbar-collapse" id="navbarNavAltMarkup1">
      <div class="navbar-nav text-md-right" *ngIf="auth == null || auth.isLogged != true">
        <a class="nav-item nav-link active" (click)="toggleOffcanvas()" routerLink="/login">{{ 'GENERAL.ACCESS_ACCOUNT' | translate }} <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="setLang('en');"><img src="/assets/images/flagUsa.png" width="16" alt="{{ 'LANGUAGE.ENGLISH' | translate }}" /><span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="setLang('pt');"><img src="/assets/images/flagBrazil.png" width="16" alt="{{ 'LANGUAGE.BRAZILIAN-PORTUGUESE' | translate }}" /><span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="setLang('es');"><img src="/assets/images/flagSpain.png" width="16" alt="{{ 'LANGUAGE.SPANISH' | translate }}" /><span class="sr-only">(current)</span></a>
      </div>
      <div class="navbar-nav text-md-right" *ngIf="auth && auth.isLogged == true">
        <a class="nav-item nav-link active btn btn-primary" (click)="toggleOffcanvas()" routerLink="/my-account">{{ 'GENERAL.WELCOME' | translate }} <strong>{{auth.user.nickname}}</strong> <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" title="{{'MY-COMPETITIONS.TITLE' | translate}}" (click)="toggleOffcanvas()" routerLink="/my-leagues"><span class="fa fa-cog"></span> <span class="sr-only">(current)</span></a>
        <div ngbDropdown>
          <div class="nav-item nav-link count-indicator">
            <a (click)="showNotifications = !showNotifications; loadNotifications()" id="notificationDropdown">
              <i class="fa fa-bell"></i>
              <span class="badge badge-pill badge-danger count" *ngIf="notifications">{{notifications.length}}</span>
            </a>
            <div class="notification-card p-4 text-left" *ngIf="showNotifications">
              <div class="row">
                <div class="col-6">
                  <h5>{{ 'NOTIFICATIONS.TITLE' | translate}}</h5>
                </div>
                <div class="col-6 text-right p-0">
                  <a (click)="markAllRead();" class="btn btn-link btn-xs text-small p-0"><em>({{'NOTIFICATIONS.MARK_ALL_AS_READ' | translate}})</em></a>
                </div>
              </div>
              <hr />
              <ng-container *ngIf="notifications && notifications.length > 0">
                <ng-container *ngFor="let not of notifications; let i = index">
                  <ng-container *ngIf="not.read_at == null">
                    <hr *ngIf="i > 0" class="mt-2" />
                    <ng-container *ngIf="not.from_entity == 'user'">
                      <div class="row mb-2">
                        <div class="col-2">
                          <a routerLink="{{not.link}}">
                            <div class="text-center text-md-left upload-wrapper uploaded-wrapper" *ngIf="not && not.FromUsers && not.FromUsers.name">
                                <div class="actual-img text-uppercase" *ngIf="not.image_url == null; else with_image">{{not.FromUsers.name | slice: 0:1}}</div>
                              <ng-template #with_image>
                                  <div class="uploaded-actual-img uploaded-img text-center">
                                      <img src="{{not.image_url}}" alt="" class="img-responsive border img-fluid rounded-circle">
                                  </div>
                              </ng-template>
                            </div>
                          </a>
                        </div>
                        <div class="col-9 notification" *ngIf="not.FromUsers && not.FromUsers.name">
                          <p *ngIf="not.link">
                            <a routerLink="{{not.link}}" class="text-small"><em>{{not.created | date: 'dd/MM/yy HH:mm'}}</em></a><br />
                            <a routerLink="{{not.link}}"><strong>{{not.FromUsers.name}}</strong></a>
                          </p>
                          <p *ngIf="!not.link">
                            <strong>{{not.FromUsers.name}}</strong>
                          </p>
                          <h6 class="preview-subject font-weight-medium"><a routerLink="{{not.link}}">{{'NOTIFICATIONS.' + not.title | translate}}</a></h6>
                          <p class="text-small">
                            <a routerLink="{{not.link}}">{{'NOTIFICATIONS.' + not.text | translate: {var1: not.vars.var1} }}</a>
                          </p>
                        </div>
                        <div class="col-1">
                          <a (click)="markAsRead(i)" title="{{ 'NOTIFICATIONS.MARK_AS_READ' | translate }}"><span class="fa fa-circle"></span></a>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="not.from_entity == 'organizer'">
                      <div class="row mb-2">
                        <div class="col-2" *ngIf="not.Organizers">
                          <a routerLink="{{not.link}}">
                            <div class="text-center text-md-left upload-wrapper uploaded-wrapper">
                                <div class="actual-img text-uppercase" *ngIf="not.image_url == null; else with_image">{{not.Organizers.name | slice: 0:1}}</div>
                              <ng-template #with_image>
                                  <div class="uploaded-actual-img uploaded-img text-center">
                                      <img src="{{not.image_url}}" alt="" class="img-responsive border img-fluid rounded-circle">
                                  </div>
                              </ng-template>
                            </div>
                          </a>
                        </div>
                        <div class="col-2" *ngIf="!not.Organizers || not.Organizers == null">
                          <a routerLink="{{not.link}}">
                            <div class="text-center text-md-left upload-wrapper uploaded-wrapper">
                              <div class="actual-img text-uppercase">NO</div>
                            </div>
                          </a>
                        </div>
                        <div class="col-9 notification">
                          <a routerLink="{{not.link}}" class="text-small"><em>{{not.created | date: 'dd/MM/yy HH:mm'}}</em></a><br />
                          <p *ngIf="not.Organizers"><a routerLink="{{not.link}}"><strong>{{not.Organizers.name}}</strong></a></p>
                          <h6 class="preview-subject font-weight-medium"><a routerLink="{{not.link}}">{{'NOTIFICATIONS.' + not.title | translate}}</a></h6>
                          <p class="text-small">
                            <a routerLink="{{not.link}}">{{'NOTIFICATIONS.' + not.text | translate: {var1: not.vars.var1} }}</a>
                          </p>
                        </div>
                        <div class="col-1">
                          <a (click)="markAsRead(i)" title="{{ 'NOTIFICATIONS.MARK_AS_READ' | translate }}"><span class="fa fa-circle"></span></a>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="not.from_entity == 'team'">
                      <div class="row mb-2">
                        <div class="col-2">
                          <a routerLink="{{not.link}}">
                            <div class="text-center text-md-left upload-wrapper uploaded-wrapper">
                                <div class="actual-img text-uppercase" *ngIf="not.image_url == null; else with_image">{{not.Teams.name | slice: 0:1}}</div>
                              <ng-template #with_image>
                                  <div class="uploaded-actual-img uploaded-img text-center">
                                      <img src="{{not.image_url}}" alt="" class="img-responsive border img-fluid rounded-circle">
                                  </div>
                              </ng-template>
                            </div>
                          </a>
                        </div>
                        <div class="col-9 notification">
                          <a routerLink="{{not.link}}" class="text-small"><em>{{not.created | date: 'dd/MM/yy HH:mm'}}</em></a><br />
                          <p><a routerLink="{{not.link}}"><strong>{{not.Teams.name}}</strong></a></p>
                          <h6 class="preview-subject font-weight-medium"><a routerLink="{{not.link}}">{{'NOTIFICATIONS.' + not.title | translate}}</a></h6>
                          <p class="text-small">
                            <a routerLink="{{not.link}}">{{'NOTIFICATIONS.' + not.text | translate: {var1: not.vars.var1} }}</a>
                          </p>
                        </div>
                        <div class="col-1">
                          <a (click)="markAsRead(i)" title="{{ 'NOTIFICATIONS.MARK_AS_READ' | translate }}"><span class="fa fa-circle"></span></a>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!notifications || (notifications && notifications.length == 0)">
                <div class="notification-item">
                  <div class="notification">
                    <p>{{'NOTIFICATIONS.NONE' | translate}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <a class="nav-item nav-link active" routerLink="/logout">{{ 'GENERAL.LOGOUT' | translate }} <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="setLang('en');"><img src="/assets/images/flagUsa.png" width="16" alt="{{ 'LANGUAGE.ENGLISH' | translate }}" /><span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="setLang('pt');"><img src="/assets/images/flagBrazil.png" width="16" alt="{{ 'LANGUAGE.BRAZILIAN-PORTUGUESE' | translate }}" /><span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" (click)="setLang('es');"><img src="/assets/images/flagSpain.png" width="16" alt="{{ 'LANGUAGE.SPANISH' | translate }}" /><span class="sr-only">(current)</span></a>
      </div>
    </div>
  </div>
</nav>