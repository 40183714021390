import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends RestService {
  private _url = 'Countries';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }
  getCountries(filter_data: any, contain_string: string = '', order_string: string = '+Countries.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'Countries.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Countries.name|like|' + filter_data.name + ';';
    }
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getCountry(id){
    return this.getApi(this._url + '/' + id);
  }

}