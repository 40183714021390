import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { RestService } from './rest.service';

@Injectable()
export class GroupsUsersService extends RestService {

    private _url = 'GroupsUsers';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insert(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    deleteGroupsUsers(user_id: number) : any {
        let data    = {user_id: user_id};
        return this.postApi(this._url + '/clean', data);
    }
    
    insertMany(info: any) : any {
        let data = {} 
        data['isArray'] = true
        data['items'] = info;
        return this.postApi(this._url, JSON.stringify(data));
    }


}