import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { ImageService } from 'src/app/services/image.service';
import { UserService } from 'src/app/services/user.service';
import { TeamsUsersService } from "src/app/services/teams-users.service";

@Component({
  selector: "top-bar",
  templateUrl: "./top-bar.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent implements OnInit {
  
  driver: any;
  loading: boolean = false;
  img_found: boolean = false;
  img_id: number;
  actual_img: string = '';
  svgFlag: string = '';
  auth: any;
  user: any;
  teams: any = [];

  constructor(
    private general: GeneralComponent,
    private imageService: ImageService,
    private teamsUsersService: TeamsUsersService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.auth =  JSON.parse(localStorage.getItem('auth'));
    this.user = this.auth.user;
    if ( this.user.birthdate != null )
      this.user.birthdate = this.general.convertDateString(this.user.birthdate.substring(0,10));
    this.load();
  }

  loadFlag(country: string = 'BRA') {
    this.svgFlag  = country;
  }

  load(){
    this.loadFlag(this.user.country);
    this.loadImage();
    this.loadTeams();
  }

  loadTeams(){
    this.teamsUsersService.getTeamsUsers([{field: 'TeamsUsers.users_id', value: this.user.id}, {field: 'TeamsUsers.role', value: 'manager'}], 'Teams.TeamsUsers;Teams.Manager.Users;Teams.Avatar', '+Teams.name', 0, '', null, false, [{field: 'TeamsUsers.id'}]).then(rs => {
      this.teams = rs.items;
    })
  }

  private loadImage(){
    this.loading  = true;
    this.imageService.getImages([{field: 'entity', value: 'avatar'}, {field: 'entity_id', value: this.user.id}])
    .then(response => {
      if ( response.summary.process == false || response.summary.message == 'error' ) {
        this.general.setMessage(response.summary.errorMessage);
      } else {
        if ( response.items.length > 0 && response.items[response.items.length -1].data.src != '' ) {
          this.img_found = true;
          this.img_id = response.items[0].data.id;
          this.imageService.resizeImage(response.items[response.items.length -1].data.src, 80, null, 'avatar', 1).then(result => {
            if ( result.summary.process == false || result.summary.message == 'error' ) {
                this.general.setMessage(result.summary.errorMessage);
                this.loading  = false;
            } else {
                this.actual_img = result.summary.full_url;
                this.loading  = false;
            }
          },
          error => {
            this.general.setMessage(<any>error); 
            this.loading = false
          });
        }
      }
      this.loading = false;  
    },
    error => {
        this.general.setMessage(<any>error); 
        this.loading = false
    });
  }
}
