import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { RestService } from './rest.service';

@Injectable()
export class LogService extends RestService {
    private _url = 'Logs';    
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insertLog(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateLog(log: any) : any {
        let data    = JSON.stringify(log);
        return this.putApi(this._url + '/' + log.id, data);
    }
    
    getLogs(filter_data: any, contains: string = '', order_string: string = '-Logs.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
              qs += item.field + '|' + item.value + ';'
            }else{
              qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    

    getLog(id: number): any {
        return this.getApi(this._url + '/' + id);
    }

}