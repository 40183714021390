<div class="steps" *ngIf="steps.length > 0">
    <hr />
    <div class="item" *ngFor="let s of steps">
        <div class="nr" [class.active]="step == s.step">
            <span>{{s.step}}</span>
            <div class="desc ml-2" *ngIf="showNames || step == s.step">
                {{s.name | translate}}
            </div>
        </div>
    </div>
</div>