import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService extends RestService {
  private _url = 'Activities';
  private _provisionalUrl = 'Stats/manual_timeline';
  
  constructor(protected _http: HttpClient) {
    super(_http); 
  }

  getActivities(filter_data: any, contain_string: string = '', order_string: any = '-Activities.id', page: number = 0, fields: string = '', limit: number = 20, report:boolean = false, groupData: any = [], operationsData: any = [], unlimited = 0): any {
    let qs: string = '';
    let paging: string = '';
    let group: string = '';
    let operations: string = '';
    let order = order_string

    if(report == true)
       qs = filter_data;
    else
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })

    if(groupData && groupData.length > 0){
      groupData.map(item => {        
           group += item.field + ';'        
      })
    }
    if(operationsData && operationsData.length > 0){
      operationsData.map(item => {        
           operations += item.operation + '|' + item.field + ';'        
      })
    }
    if(Array.isArray(order)){
      const orderArray = order
      order = ''
      orderArray.map(item => order += `${item.order}${item.field};`)
    }
    
    
    
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order, contains: contain_string, fields: fields, group: group, operations: operations, unlimited: unlimited } });
  }

  getActivitiesProv(user_id): any {
    
    return this.getApi(this._provisionalUrl + '?user_id=' + user_id);
  }
  
  getActivitie(id){
    return this.getApi(this._url + '/' + id);
  } 

}