<app-loading [loading]="loading"></app-loading>
<!-- [ngStyle]="{'min-height': '480px', 'background': 'url(\'' + actual_img_banner + '\') no-repeat center center', 'background-size': 'cover'}"> -->
<div class="full_banner" *ngIf="config.top_banner == '1' && actual_img_banner && actual_img_banner != ''">
  <img class="img img-fluid" width="100%" src="{{actual_img_banner}}" />
</div>
<ng-container *ngIf="league && !loading">
  <div class="container mb-5">
    <div class="row mt-3" *ngIf="config.header == '1'">
      <div class="col-12 col-md-2 h-100 align-top text-center text-md-left upload-wrapper uploaded-wrapper">
          <div class="actual-img actual-img-big text-uppercase" *ngIf="actual_img == ''; else with_image">{{league.name | slice: 0:1}}</div>
          <ng-template #with_image>
              <div class="uploaded-actual-img uploaded-img text-center">
                  <img src="{{actual_img}}" alt="{{league.name}}" class="img-responsive border img-fluid rounded-circle">
              </div>
          </ng-template>
      </div>
      <div class="col-12 col-md-10 mt-3 mt-md-0 text-md-left text-center">
        <h2>{{league.name}}</h2>
        <div class="row">
          <div class="col-12">
            <small>
              <div class="row">
                <div class="col-12 col-md-9">
                  <span *ngIf="league.Organizers && league.Organizers.name != ''">
                    {{ 'LEAGUES.ORGANIZED_BY' | translate }}: <strong><a routerLink="/organizers/view/{{league.Organizers.url}}">{{league.Organizers.name}}</a></strong><br />
                  </span>
                  <span *ngIf="league.subscription_start != null">{{ 'LEAGUES.SUBSCRIPTION_PERIOD' | translate }}: <strong><em>{{league.subscription_start | date: 'dd/MM/yyyy'}}</em></strong> {{ 'GENERAL.TO' | translate }} <strong><em>{{league.subscription_end | date: 'dd/MM/yyyy'}}</em></strong><br /></span>
                  <span *ngIf="league.Platforms != null">{{ 'LEAGUES.PLATFORM' | translate }}: <strong>{{league.Platforms.name}}</strong><br /></span>
                  <span *ngIf="league.Simulators != null">{{ 'LEAGUES.SIMULATOR' | translate }}: <strong>{{league.Simulators.name}}</strong><br /></span>
                  <span *ngIf="subscribed != null && league.number_of_participants != null && league.subscription_type != 'open'">{{ 'RACES.SUBSCRIBED' | translate }}: <strong>{{subscribed}}/{{league.number_of_participants}}</strong></span>
                  <span *ngIf="subscribed != null && league.number_of_participants != null && league.subscription_type == 'open'">{{ 'RACES.SUBSCRIBED' | translate }}: <strong>{{subscribed}}</strong></span>
                </div>
                <div class="col-12 col-md-3 text-md-right mt-3 mt-md-0">
                  <div *ngIf="league.opened == '1' && league.subscription_type == 'closed'">
                    <small>{{'SUBSCRIPTION.PRICE' | translate}}</small>
                    <h5 *ngIf="league.paid == '0'">{{'GENERAL.FREE' | translate}}</h5>
                    <h5 *ngIf="league.paid == '1'">{{ league.price | currency: ' ' }} {{ league.currency }}</h5>
                  </div>
                  <a *ngIf="league.opened == '1' && league.subscription_type == 'closed'" 
                  routerLink="/leagues/subscription/{{league.url}}"
                  [class.disabled]="subscribed >= league.number_of_participants"
                  class="btn btn-primary white mr-1">
                    <span *ngIf="subscribed < league.number_of_participants">
                      <i class="fa fa-check"></i>&nbsp;{{ 'LEAGUES.RACE_IN_THIS_LEAGUE'| translate }}
                    </span>
                    <span *ngIf="subscribed >= league.number_of_participants">
                      <i class="fa fa-times"></i>&nbsp;{{ 'RACES.CLOSED'| translate }}
                    </span>
                  </a>
                  <p class="mt-2" *ngIf="regulation_file_url != ''">
                    <a href="{{regulation_file_url}}" target="_blank"
                    class="btn btn-outline-primary mt-1 mt-md-0">
                      <i class="fa fa-download"></i>&nbsp;{{ 'LEAGUES.DOWNLOAD-REGULATION'| translate }}
                    </a>
                  </p>
                </div>
              </div>
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="config.description == '1' && league.description && league.description != null && league.description != ''">
      <div class="col-12">
        {{ 'RACES.DESCRIPTION' | translate }}:
        <div class="card">
          <div class="card-body" [class.readMore]="!readMore && league.description.length > 800" [innerHTML]="league.description"></div>
        </div>
        <div class="text-center" *ngIf="league.description.length > 800">
          <a class="btn btn-outline-primary mt-1" *ngIf="!readMore" (click)="readMore = !readMore">{{'GENERAL.READ-MORE' | translate}}</a>
          <a class="btn btn-outline-primary mt-1" *ngIf="readMore" (click)="readMore = !readMore">{{'GENERAL.READ-LESS' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="container mb-5">
    <div class="row" *ngIf="config.show_menu == '1'">
      <div class="col-12 text-center">
        <a class="btn btn-sm btn-outline-primary mt-2 mt-md-0 ml-1" *ngIf="config.show_calendar == '1'" [class.active]="tab == 'calendar'" (click)="selectTab('calendar');" rel="noopener">{{ 'LEAGUES.CALENDAR'| translate }}</a>
        <a class="btn btn-sm btn-outline-primary mt-2 mt-md-0 ml-1" *ngIf="config.show_subscriptions == '1'" [class.active]="tab == 'subscriptions'" (click)="selectTab('subscriptions');" rel="noopener">{{ 'RACES.SUBSCRIBED'| translate }}</a>
        <a class="btn btn-sm btn-outline-primary mt-2 mt-md-0 ml-1" *ngIf="config.show_classification_drivers == '1'" [class.active]="tab == 'classification_drivers'" (click)="selectTab('classification_drivers');" rel="noopener">{{ 'LEAGUES.CLASSIFICATION_DRIVERS'| translate }}</a>
        <a *ngIf="config.show_classification_teams == '1' && league.teams_standings == '1'" class="btn btn-sm btn-outline-primary mt-2 mt-md-0 ml-1" [class.active]="tab == 'classification_teams'" (click)="selectTab('classification_teams');" rel="noopener">{{ 'LEAGUES.CLASSIFICATION_TEAMS'| translate }}</a>
        <a *ngIf="config.show_rules == '1' && league.Rules && league.Rules.length > 0" class="btn btn-sm btn-outline-primary mt-2 mt-md-0 ml-1" [class.active]="tab == 'rules'" (click)="selectTab('rules');" rel="noopener">{{ 'LEAGUES.RULES'| translate }}</a>
      </div>
    </div>
    <hr *ngIf="config.show_menu == '1'" />
    <div class="row mt-3">
      <div class="col-12 mt-2">
        <ng-container *ngIf="tab == 'subscriptions' && listDrivers.length == 0">
          <h3>{{ 'RESULTS.DRIVERS' | translate}}</h3>
          <div class="card">
            <div class="card-body">
              {{ 'SUBSCRIPTION.NO_SUBSCRIPTIONS' | translate }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="tab == 'subscriptions' && listDrivers.length > 0">
          <h3>{{ 'RESULTS.DRIVERS' | translate}}
            <small>{{listDrivers.length}} {{'GENERAL.DRIVERS-FOUND' | translate}}: 
              <span *ngIf="summary['a']" class="mr-2"><small>{{'GENERAL.CONFIRMED' | translate}}: <strong>{{summary['a']}}</strong></small></span>
              <span *ngIf="summary['i']" class="mr-2"><small>{{'GENERAL.WAITING' | translate}}: <strong>{{summary['i']}}</strong></small></span>
              <span *ngIf="summary['w']" class="mr-2 text-danger"><small>{{'LEAGUES.WAITING-TITLE' | translate}}: <strong>{{summary['w']}}</strong></small></span>
              <span *ngIf="summary['c']" class="mr-2 disabled"><small>{{'GENERAL.CANCELLED' | translate}}: <strong>{{summary['c']}}</strong></small></span>
            </small>
          </h3>
          <div class="table-responsive">
            <table class="table table-condensed table-bordered table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ 'RESULTS.DRIVER' | translate}}</th>
                  <th>{{ 'RACES.VEHICLE' | translate}}</th>
                  <th>{{ 'GENERAL.STATUS' | translate}}</th>
                  <th *ngIf="league.categories != null && league.categories != ''">{{ 'LEAGUES.CATEGORY' | translate}}</th>
                </tr>
              </thead>
              <tr *ngFor="let driver of listDrivers" [class.text-black-50]="driver.data.status == 'i'" [class.text-danger]="driver.data.status == 'w'" [class.disabled]="driver.data.status == 'c'">
                <td>#{{driver.data.number}}</td>
                <td><a href="/drivers/view/{{driver.data.Users.url}}" target="_blank" *ngIf="driver.data.Users">
                  <elo *ngIf="config.show_rating == '1'" [driver]="driver.data.Users"></elo>{{driver.data.Users.name}}</a>
                  <ng-container *ngIf="driver.data.RacesUsersSlots != null">
                    <div *ngFor="let slot of driver.data.RacesUsersSlots"> / <a href="/drivers/view/{{slot.Users.url}}" target="_blank">
                      <elo *ngIf="config.show_rating == '1'" [driver]="slot.Users"></elo>{{slot.Users.name}}</a></div>
                  </ng-container>
                  <ng-container *ngIf="driver.data.team_key != null">
                    <br /><small>
                      <elo *ngIf="config.show_rating == '1'" [team]="driver.data.Teams"></elo>
                      <strong><em>{{driver.data.team_key}}</em></strong></small>
                  </ng-container>
                </td>
                <td *ngIf="driver.data.Vehicles && driver.data.Vehicles != null">{{driver.data.Vehicles.name}}</td>
                <td *ngIf="!driver.data.Vehicles || driver.data.Vehicles == null">&nbsp;</td>
                <td *ngIf="driver.data.status == 'a'">{{'GENERAL.CONFIRMED' | translate}}</td>
                <td *ngIf="driver.data.status == 'i'">{{'GENERAL.WAITING' | translate}}</td>
                <td *ngIf="driver.data.status == 'w'">{{'LEAGUES.WAITING-TITLE' | translate}}</td>
                <td *ngIf="driver.data.status == 'c'">{{'GENERAL.CANCELLED' | translate}}</td>
                <td *ngIf="league.categories != null && league.categories != '' && driver.data.category != ''">{{driver.data.category}}</td>
                <td *ngIf="league.categories != null && league.categories != '' && driver.data.category == ''">{{'LEAGUES.GENERAL_CATEGORY-NAME' | translate}}</td>
              </tr>
            </table>
          </div>
        </ng-container>
        <ng-container *ngIf="tab == 'calendar'">
          <h3>{{ 'LEAGUES.CALENDAR'| translate }}</h3>
          <div class="table-responsive" *ngIf="league.Races.length > 0; else no_results_races">
            <table class="table table-condensed table-bordered table-striped">
              <thead>
                  <tr>
                      <th>{{ 'CALENDAR.RACE.DATETIME' | translate}}</th>
                      <th>{{ 'CALENDAR.RACE.TRACK' | translate}}<br /><em><strong>{{ 'CALENDAR.RACE.VEHICLE_CLASS' | translate}}/{{ 'CALENDAR.RACE.VEHICLE' | translate}}</strong></em></th>
                      <th>{{ 'CALENDAR.RACE.LENGTH' | translate}}</th>
                      <th *ngIf="league.subscription_type == 'open'">{{ 'CALENDAR.RACE.SLOTS' | translate}}</th>
                      <th>{{ 'CALENDAR.RACE.POLE' | translate}}<br />{{ 'CALENDAR.RACE.FASTEST_LAP' | translate}}<br >{{ 'CALENDAR.RACE.WINNER' | translate}}</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let race of league.Races">
                      <td><a routerLink="/leagues/race/{{race.id}}">{{race.datetime | date: 'dd/MM/yyyy HH:mm'}}</a></td>
                      <td><a routerLink="/leagues/race/{{race.id}}">
                        <span *ngIf="race.Tracks != null">{{race.Tracks.name}}<br /></span>
                        <span *ngIf="race.Tracks == null">{{race.track}}<br /></span>
                        <em *ngIf="race.Vehicles == null && race.VehiclesClasses != null">{{race.VehiclesClasses.name}}</em>
                        <em *ngIf="race.VehiclesClasses != null && race.Vehicles != null && race.Vehicles.name == race.VehiclesClasses.name"><strong>{{race.Vehicles.name}}</strong></em>
                        <em *ngIf="race.VehiclesClasses != null && race.Vehicles != null && race.Vehicles.name != race.VehiclesClasses.name">{{race.VehiclesClasses.name}} / <strong>{{race.Vehicles.name}}</strong></em>
                        </a>
                      </td>
                      <td *ngIf="race.time > 0"><a routerLink="/leagues/race/{{race.id}}">{{race.time}} {{ 'CALENDAR.RACE.MINUTES' | translate }}</a></td>
                      <td *ngIf="race.time == 0 && race.laps > 0"><a routerLink="/leagues/race/{{race.id}}">{{race.laps}} {{ 'CALENDAR.RACE.LAPS' | translate }}</a></td>
                      <td *ngIf="league.subscription_type == 'open'">
                        <a routerLink="/leagues/race/{{race.id}}">{{race.ActiveRacesUsers.length}}/{{race.slots}}</a>
                        <ng-container *ngIf="league.opened == '1'">
                          <a class="m-0 ml-md-2 btn btn-primary btn-xs white" *ngIf="race.ActiveRacesUsers.length < race.slots && !race.past" routerLink="/races/subscribe/{{race.id}}"><span class="fa fa-check"></span> {{'RACES.SUBSCRIBE' | translate}}</a>
                          <a class="m-0 ml-md-2 btn btn-primary disabled btn-xs white" *ngIf="race.ActiveRacesUsers.length < race.slots && race.past"><span class="fa fa-times"></span> {{'RACES.CLOSED' | translate}}</a>
                          <a class="m-0 ml-md-2 btn btn-primary disabled btn-xs white" *ngIf="race.ActiveRacesUsers.length >= race.slots"><span class="fa fa-thumbs-down"></span> {{'RACES.CLOSED' | translate}}</a>
                        </ng-container>
                      </td>
                      <td class="text-height-2">
                        <ng-container *ngIf="race.winners">
                          <div *ngFor="let winner of race.winners">
                            <a routerLink="/leagues/race/{{race.id}}">
                              <span class="badge badge-primary mr-1" *ngIf="winner.data.VehiclesClasses != null">{{winner.data.VehiclesClasses.name}}</span>
                              <span class="badge badge-warning mr-1">WIN</span>
                              <span class="badge badge-dark mr-1" *ngIf="winner.data.category != ''">{{winner.data.category}}</span>
                              <span *ngIf="winner.data.name != ''">{{winner.data.name}}</span>
                              <span *ngIf="winner.data.name == '' || winner.data.name == null"><em>{{'GENERAL.NOT-REGISTERED' | translate}}</em></span>
                            </a>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="race.poles">
                          <div *ngFor="let pole of race.poles">
                            <a routerLink="/leagues/race/{{race.id}}">
                              <span class="badge badge-primary mr-1" *ngIf="pole.data.VehiclesClasses != null">{{pole.data.VehiclesClasses.name}}</span>
                              <span class="badge badge-success mr-1">1ST</span>
                              <span class="badge badge-dark mr-1" *ngIf="pole.data.category != ''">{{pole.data.category}}</span>
                              <span *ngIf="pole.data.name != ''">{{pole.data.name}}</span>
                              <span *ngIf="pole.data.name == '' || pole.data.name == null"><em>{{'GENERAL.NOT-REGISTERED' | translate}}</em></span>
                            </a>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="race.fastlaps">
                          <div *ngFor="let fast of race.fastlaps">
                            <a routerLink="/leagues/race/{{race.id}}">
                              <span class="badge badge-primary mr-1" *ngIf="fast.data.VehiclesClasses != null">{{fast.data.VehiclesClasses.name}}</span>
                              <span class="badge badge-warning mr-1">FLA</span>
                              <span class="badge badge-dark mr-1" *ngIf="fast.data.category != ''">{{fast.data.category}}</span>
                              <span *ngIf="fast.data.name != ''">{{fast.data.name}}</span>
                              <span *ngIf="fast.data.name == '' || fast.data.name == null"><em>{{'GENERAL.NOT-REGISTERED' | translate}}</em></span>
                            </a>
                          </div>
                        </ng-container>
                        <span *ngIf="race.PoleUser && race.PoleUser.name != ''">
                          &nbsp;<a routerLink="/leagues/race/{{race.id}}">{{race.PoleUser.name}}</a><br />
                        </span>
                        <span *ngIf="race.FastestLapUser && race.FastestLapUser.name != ''">
                          &nbsp;<a routerLink="/leagues/race/{{race.id}}">{{race.FastestLapUser.name}}</a><br />
                        </span>
                        <span *ngIf="race.Winner && race.Winner.name != ''">
                          <span class="badge badge-warning">WIN</span>&nbsp;<a routerLink="/leagues/race/{{race.id}}">{{race.Winner.name}}</a>
                        </span>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <ng-template #no_results_races>
              <div class="card">
                  <div class="card-body">
                      <small>{{ 'CALENDAR.NO_RESULTS' | translate }}</small>
                  </div>
              </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="tab == 'classification_drivers'">
          <div class="row mb-4">
            <div class="col-12 text-center text-md-left">
              <h3>{{ 'LEAGUES.CLASSIFICATION_DRIVERS'| translate }}</h3>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-6 text-center text-md-left mb-2 mb-md-0">
                  <ng-container *ngIf="classes.length > 0">
                    <ng-container *ngFor="let cl of classes">
                      <a *ngIf="cl != null" class="btn btn-sm btn-outline-primary mt-2 mt-md-0 ml-1" [class.active]="class_id == cl.id" (click)="changeClass(cl.id);" rel="noopener">{{cl.name}}</a>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="categories && categories.length > 1">
                    <a *ngIf="league.general_category == '1'" (click)="changeCategory('GENERAL');" 
                        [class.active]="'GENERAL' == category" class="btn btn-outline-primary mr-1">{{'LEAGUES.GENERAL_CATEGORY-NAME' | translate}}</a>
                    <a *ngFor="let c of categories" (click)="changeCategory(c);" 
                      class="btn btn-outline-primary mr-1" 
                      [class.active]="c.trim() == category">{{c.trim()}}</a>
                  </ng-container>
                </div>
                <div class="col-12 col-md-6 text-center text-md-right" *ngIf="config.show_modes == 'all'">
                  <a class="btn btn-primary btn-sm white" [class.active]="mode == 'minimal'" (click)="mode = 'minimal'" ngbTooltip="{{'GENERAL.MODES.MINIMAL' | translate}}"><span class="fa fa-th-list"></span></a>
                  <a class="btn btn-primary btn-sm white ml-1" [class.active]="mode == 'reduced'" (click)="mode = 'reduced'" ngbTooltip="{{'GENERAL.MODES.REDUCED' | translate}}"><span class="fa fa-th-list"></span></a>
                  <a class="btn btn-primary btn-sm white ml-1" [class.active]="mode == 'complete'" (click)="mode = 'complete'" ngbTooltip="{{'GENERAL.MODES.COMPLETE' | translate}}"><span class="fa fa-table"></span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" *ngIf="standings && standings[0] && standings[0].Races && standings[0].Races.length > 0; else no_results_drivers">
            <table class="table table-condensed results-table table-bordered table-striped">
              <thead>
                  <tr>
                      <th rowspan="2">#</th>
                      <th rowspan="2">{{ 'RESULTS.DRIVER' | translate}}<br /><em><small>{{ 'TEAMS.TEAM' | translate}}</small></em></th>
                      <ng-container *ngFor="let race of standings[0].Races; let i = index">
                        <th colspan="6" *ngIf="mode == 'complete'" class="rotate big">
                          <small>{{i+1}} | 
                            <em>{{race.Race.datetime | date : 'dd/MM HH:mm'}}</em>
                            <br />
                            <strong *ngIf="race.Race.Tracks && race.Race.Tracks != null">{{race.Race.Tracks.name.substring(0,20)}}</strong>
                            <strong *ngIf="race.Race.Tracks == null">{{race.Race.track.substring(0,20)}}</strong>
                          </small>
                        </th>
                        <th colspan="2" *ngIf="mode == 'reduced'" class="rotate">
                          <small>{{i+1}} | 
                            <em>{{race.Race.datetime | date : 'dd/MM HH:mm'}}</em>
                            <br />
                            <strong *ngIf="race.Race.Tracks && race.Race.Tracks != null">{{race.Race.Tracks.name.substring(0,20)}}</strong>
                            <strong *ngIf="race.Race.Tracks == null">{{race.Race.track.substring(0,20)}}</strong>
                          </small>
                        </th>
                      </ng-container>
                      <th rowspan="2"></th>
                  </tr>
                  <tr *ngIf="mode == 'complete' || mode == 'reduced'">
                    <ng-container *ngFor="let race of standings[0].Races; let i = index">
                      <th class="text-center" class="pos" *ngIf="mode == 'complete' || mode == 'reduced'"><span title="{{'POINTS.POSITION' | translate}}">{{'RESULTS.POS' | translate}}</span></th>
                      <th class="text-center" *ngIf="mode == 'complete'"><span title="{{'POINTS.RACE_POINTS' | translate}}">{{'RESULTS.RAC' | translate}}</span></th>
                      <th class="text-center" *ngIf="mode == 'complete'"><span title="{{'RESULTS.POLE_POSITION' | translate}}">{{'RESULTS.POL' | translate}}</span></th>
                      <th class="text-center" *ngIf="mode == 'complete'"><span title="{{'RESULTS.FASTEST_LAP' | translate}}">{{'RESULTS.FLA' | translate}}</span></th>
                      <th class="text-center" *ngIf="mode == 'complete'"><span title="{{'RESULTS.NO_INCIDENT' | translate}}">{{'RESULTS.NOI' | translate}}</span></th>
                      <th class="text-center" *ngIf="mode == 'complete' || mode == 'reduced'">
                        <span title="{{'GENERAL.SUBTOTAL' | translate}}">{{'RESULTS.SUB' | translate}}</span>
                        <ng-container *ngIf="race.Race.double_points > 0">
                          <br />
                          <span class="badge badge-primary">2x</span>
                        </ng-container>
                      </th>
                    </ng-container>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let st of standings; let i = index">
                    <td class="pos">{{i+1}}</td>
                    <td>
                      <a routerLink="/drivers/view/{{st.url}}">
                        <elo *ngIf="config.show_rating == '1'" [driver]="st"></elo>{{st.name}}
                        <span *ngIf="league.teams_standings == '1'">
                          <br />
                          <em><small>{{st.team_key}}</small></em>
                        </span>
                      </a>
                    </td>
                    <ng-container *ngFor="let race1 of st.Races; let i1 = index">
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && (mode == 'complete' || mode == 'reduced')"><em>{{race1.Result[0].position}}</em></td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && (mode == 'complete' || mode == 'reduced')">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && (mode == 'complete' || mode == 'reduced')">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && (mode == 'complete' || mode == 'reduced')"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && mode == 'complete'">{{race1.Result[0].points}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && mode == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && mode == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && mode == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && mode == 'complete'">{{race1.Result[0].extra_point_pole}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && mode == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && mode == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && mode == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && mode == 'complete'">{{race1.Result[0].extra_point_fastest_lap}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && mode == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && mode == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && mode == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && mode == 'complete'">{{race1.Result[0].extra_point_no_incident}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && mode == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && mode == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && mode == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && (mode == 'complete' || mode == 'reduced')">
                        <ng-container *ngIf="race1.Result[0].punitions && race1.Result[0].punitions.length > 0">
                        <a (click)="hidePopovers(race1.Result[0].id);popover[race1.Result[0].id] = !popover[race1.Result[0].id]">
                            <div class="popover popover-bottom" *ngIf="popover[race1.Result[0].id] == true">
                              <div class="popover-body">
                                <ul class="list-unstyled">
                                  <li *ngFor="let p of race1.Result[0].punitions">
                                    <span class="badge badge-success" *ngIf="p.data.action == 'positive'">+{{p.data.number}}</span>
                                    <span class="badge badge-danger" *ngIf="p.data.action == 'negative'">-{{p.data.number}}</span>
                                    - <em><small>{{p.data.reason}}</small></em>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <strong>{{race1.Result[0].subtotal_points}}</strong>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="!race1.Result[0].punitions || race1.Result[0].punitions == null || race1.Result[0].punitions.length == 0">
                          <strong>{{race1.Result[0].subtotal_points}}</strong>
                        </ng-container>
                      </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && (mode == 'complete' || mode == 'reduced')">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && (mode == 'complete' || mode == 'reduced')">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && (mode == 'complete' || mode == 'reduced')"> </td>
                    </ng-container>
                    <td [class.fixed]="mode == 'complete' || mode == 'reduced'">{{st.subtotal_points}}</td>
                  </tr>
              </tbody>
            </table>
            <hr />
            <h5>{{'GENERAL.LEGEND' | translate}}</h5>
            <ul class="legend">
              <li *ngIf="mode == 'complete' || mode == 'reduced'">{{'RESULTS.POS' | translate}}: {{'POINTS.POSITION' | translate}}</li>
              <li *ngIf="mode == 'complete'">{{'RESULTS.RAC' | translate}}: {{'POINTS.RACE_POINTS' | translate}}</li>
              <li *ngIf="mode == 'complete'">{{'RESULTS.POL' | translate}}: {{'RESULTS.POLE_POSITION' | translate}}</li>
              <li *ngIf="mode == 'complete'">{{'RESULTS.FLA' | translate}}: {{'RESULTS.FASTEST_LAP' | translate}}</li>
              <li *ngIf="mode == 'complete'">{{'RESULTS.NOI' | translate}}: {{'RESULTS.NO_INCIDENT' | translate}}</li>
              <li *ngIf="mode == 'complete' || mode == 'reduced'">{{'RESULTS.SUB' | translate}}: {{'GENERAL.SUBTOTAL' | translate}}</li>
              <li>{{'POINTS.TOT' | translate}}: {{'POINTS.TOTAL_POINTS' | translate}}</li>
              <li *ngIf="mode == 'complete' || mode == 'reduced'">{{'RESULTS.DNS' | translate}}: {{'RESULTS.DID_NOT_START' | translate}}</li>
              <li *ngIf="mode == 'complete' || mode == 'reduced'">{{'RESULTS.DNF' | translate}}: {{'RESULTS.DID_NOT_FINISH' | translate}}</li>
            </ul>
          </div>
          <ng-template #no_results_drivers>
              <div class="card">
                  <div class="card-body">
                      <small>{{ 'CALENDAR.NO_RESULTS' | translate }}</small>
                  </div>
              </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="tab == 'classification_teams'">
          <div class="row mb-4">
            <div class="col-12 text-center text-md-left">
              <h3>{{ 'LEAGUES.CLASSIFICATION_TEAMS'| translate }}</h3>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-6 text-center text-md-left mb-2 mb-md-0">
                  <ng-container *ngIf="categories && categories.length > 1">
                    <a *ngIf="league.general_category == '1'" (click)="changeCategory('GENERAL');" 
                        [class.active]="'GENERAL' == category" class="btn btn-outline-primary mr-1">{{'LEAGUES.GENERAL_CATEGORY-NAME' | translate}}</a>
                    <a *ngFor="let c of categories" (click)="changeCategory(c);" 
                      class="btn btn-outline-primary mr-1" 
                      [class.active]="c.trim() == category">{{c.trim()}}</a>
                  </ng-container>
                </div>
                <div class="col-12 col-md-6 text-center text-md-right">
                  <a class="btn btn-primary btn-sm white" [class.active]="modeT == 'minimal'" (click)="modeT = 'minimal'" ngbTooltip="{{'GENERAL.MODES.MINIMAL' | translate}}"><span class="fa fa-th-list"></span></a>
                  <a class="btn btn-primary btn-sm white ml-1" [class.active]="modeT == 'reduced'" (click)="modeT = 'reduced'" ngbTooltip="{{'GENERAL.MODES.REDUCED' | translate}}"><span class="fa fa-th-list"></span></a>
                  <a class="btn btn-primary btn-sm white ml-1" [class.active]="modeT == 'complete'" (click)="modeT = 'complete'" ngbTooltip="{{'GENERAL.MODES.COMPLETE' | translate}}"><span class="fa fa-table"></span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" *ngIf="standingsT && standingsT[0] && standingsT[0].Races && standingsT[0].Races.length > 0; else no_results_teams">
            <table class="table table-condensed results-table table-bordered table-striped">
              <thead>
                  <tr>
                      <th rowspan="2">#</th>
                      <th rowspan="2">{{ 'TEAMS.TEAM' | translate}}</th>
                      <ng-container *ngFor="let race of standingsT[0].Races; let i = index">
                        <th colspan="5" *ngIf="modeT == 'complete'" class="rotate big">
                          <small>{{i+1}} | 
                            <em>{{race.Race.datetime | date : 'dd/MM HH:mm'}}</em>
                          </small>
                          <br />
                          <strong *ngIf="race.Race.Tracks && race.Race.Tracks != null">{{race.Race.Tracks.name.substring(0,20)}}</strong>
                          <strong *ngIf="race.Race.Tracks == null">{{race.Race.track.substring(0,20)}}</strong>
                        </th>
                        <th *ngIf="modeT == 'reduced'" class="rotate">
                          <small>{{i+1}} | 
                            <em>{{race.Race.datetime | date : 'dd/MM HH:mm'}}</em>
                            <br />
                            <strong *ngIf="race.Race.Tracks && race.Race.Tracks != null">{{race.Race.Tracks.name.substring(0,20)}}</strong>
                            <strong *ngIf="race.Race.Tracks == null">{{race.Race.track.substring(0,20)}}</strong>
                          </small>
                        </th>
                      </ng-container>
                      <th rowspan="2"></th>
                  </tr>
                  <tr *ngIf="modeT == 'complete' || modeT == 'reduced'">
                    <ng-container *ngFor="let race of standingsT[0].Races; let i = index">
                      <th class="text-center" *ngIf="modeT == 'complete'"><span title="{{'POINTS.RACE_POINTS' | translate}}">{{'RESULTS.RAC' | translate}}</span></th>
                      <th class="text-center" *ngIf="modeT == 'complete'"><span title="{{'RESULTS.POLE_POSITION' | translate}}">{{'RESULTS.POL' | translate}}</span></th>
                      <th class="text-center" *ngIf="modeT == 'complete'"><span title="{{'RESULTS.FASTEST_LAP' | translate}}">{{'RESULTS.FLA' | translate}}</span></th>
                      <th class="text-center" *ngIf="modeT == 'complete'"><span title="{{'RESULTS.NO_INCIDENT' | translate}}">{{'RESULTS.NOI' | translate}}</span></th>
                      <th class="text-center" *ngIf="modeT == 'complete' || modeT == 'reduced'"><span title="{{'GENERAL.SUBTOTAL' | translate}}">{{'RESULTS.SUB' | translate}}</span>
                        <ng-container *ngIf="race.Race.double_points > 0">
                          <br />
                          <span class="badge badge-primary">2x</span>
                        </ng-container>
                      </th>
                    </ng-container>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let st of standingsT; let i = index">
                    <td class="pos">{{i+1}}</td>
                    <td class="nowrap">
                      <a routerLink="/teams/view/{{st.url}}"><elo *ngIf="config.show_rating == '1'" [team]="st"></elo>{{st.team_key}}</a>
                    </td>
                    <ng-container *ngFor="let race1 of st.Races; let i1 = index">
                      <!-- <td [class]="color[i1]" *ngIf="!race1.Result[0] && (modeT == 'complete' || modeT == 'reduced')"> </td> -->
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && modeT == 'complete'">{{race1.Result[0].points}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && modeT == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && modeT == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && modeT == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && modeT == 'complete'">{{race1.Result[0].extra_point_pole}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && modeT == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && modeT == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && modeT == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && modeT == 'complete'">{{race1.Result[0].extra_point_fastest_lap}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && modeT == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && modeT == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && modeT == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && modeT == 'complete'">{{race1.Result[0].extra_point_no_incident}}</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && modeT == 'complete'">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && modeT == 'complete'">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && modeT == 'complete'"> </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns != '1' && race1.Result[0].dnf != '1' && (modeT == 'complete' || modeT == 'reduced')">
                        <strong>{{race1.Result[0].subtotal_points}}</strong>
                      </td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dns == '1' && (modeT == 'complete' || modeT == 'reduced')">DNS</td>
                      <td [class]="color[i1]" [class.danger]="race1.Result[0].discard == '1'" *ngIf="race1.Result[0] && race1.Result[0].dnf == '1' && (modeT == 'complete' || modeT == 'reduced')">DNF</td>
                      <td [class]="color[i1]" *ngIf="!race1.Result[0] && (modeT == 'complete' || modeT == 'reduced')"> </td>
                    </ng-container>
                    <td [class.fixed]="modeT == 'complete' || modeT == 'reduced'">{{st.subtotal_points}}</td>
                  </tr>
              </tbody>
            </table>
            <hr />
            <h5>{{'GENERAL.LEGEND' | translate}}</h5>
            <ul class="legend">
              <li *ngIf="modeT == 'complete'">{{'RESULTS.RAC' | translate}}: {{'POINTS.RACE_POINTS' | translate}}</li>
              <li *ngIf="modeT == 'complete'">{{'RESULTS.POL' | translate}}: {{'RESULTS.POLE_POSITION' | translate}}</li>
              <li *ngIf="modeT == 'complete'">{{'RESULTS.FLA' | translate}}: {{'RESULTS.FASTEST_LAP' | translate}}</li>
              <li *ngIf="modeT == 'complete'">{{'RESULTS.NOI' | translate}}: {{'RESULTS.NO_INCIDENT' | translate}}</li>
              <li *ngIf="modeT == 'complete' || modeT == 'reduced'">{{'RESULTS.SUB' | translate}}: {{'GENERAL.SUBTOTAL' | translate}}</li>
              <li>{{'POINTS.TOT' | translate}}: {{'POINTS.TOTAL_POINTS' | translate}}</li>
              <li *ngIf="modeT == 'complete' || modeT == 'reduced'">{{'RESULTS.DNS' | translate}}: {{'RESULTS.DID_NOT_START' | translate}}</li>
              <li *ngIf="modeT == 'complete' || modeT == 'reduced'">{{'RESULTS.DNF' | translate}}: {{'RESULTS.DID_NOT_FINISH' | translate}}</li>
            </ul>
          </div>
          <ng-template #no_results_teams>
              <div class="card">
                  <div class="card-body">
                      <small>{{ 'CALENDAR.NO_RESULTS' | translate }}</small>
                  </div>
              </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="tab == 'rules'">
          <h3>{{ 'LEAGUES.RULES'| translate }}</h3>
          <div class="row mt-3">
            <div class="col-12">
              <h4>{{ 'LEAGUES.POINTS' | translate }}</h4>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div *ngIf="league.Points.length > 0; else no_results_points">
                    <ul class="list-group">
                        <ng-container *ngFor="let point of league.Points">
                            <li class="list-group-item">
                                {{point.position}}º - {{point.points}} {{ 'POINTS.POINTS2' | translate}}
                            </li>
                        </ng-container>
                    </ul>
                  </div>
                  <ng-template #no_results_points>
                    <div class="card">
                        <div class="card-body">
                            <p>{{ 'POINTS.NO_RESULTS' | translate }}</p>
                        </div>
                    </div>
                  </ng-template>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0">
                  <h5>{{ 'POINTS.EXTRA_POINTS' | translate }}</h5>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'POINTS.EXTRA_POLE' | translate }}</label>
                    <div class="col-sm-6">
                      {{league.extra_pole}} {{ 'POINTS.POINTS2' | translate }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'POINTS.EXTRA_FASTEST_LAP' | translate }}</label>
                    <div class="col-sm-6">
                      {{league.extra_fastest_lap}} {{ 'POINTS.POINTS2' | translate }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'POINTS.EXTRA_NO_INCIDENT' | translate }}</label>
                    <div class="col-sm-6">
                      {{league.extra_no_incident}} {{ 'POINTS.POINTS2' | translate }}
                    </div>
                  </div>
                  <hr />
                  <h5>{{ 'RULES.GENERAL_RULES' | translate }}</h5>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.RULES_PENALTIES' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].rules_penalties == '0'">{{ 'GENERAL.OFF' | translate }}</span>
                      <span *ngIf="league.Rules[0].rules_penalties != '0'">{{ 'GENERAL.ON' | translate }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.TRACK_LIMITS' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].track_limits == '0'">{{ 'GENERAL.OFF' | translate }}</span>
                      <span *ngIf="league.Rules[0].track_limits != '0'">{{ 'GENERAL.ON' | translate }}</span>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="league.Rules[0].track_limits != '0'">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.ALLOWABLE_TIME_PENALTY' | translate }}</label>
                    <div class="col-sm-6">
                      {{league.Rules[0].allowable_time_penalty}}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.DRIVE_THROUGH_PENALTIES' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].dt_penalties == '0'">{{ 'GENERAL.OFF' | translate }}</span>
                      <span *ngIf="league.Rules[0].dt_penalties != '0'">{{ 'GENERAL.ON' | translate }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.PIT_EXIT_PENALTY' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].pit_exit_penalty == '0'">{{ 'GENERAL.OFF' | translate }}</span>
                      <span *ngIf="league.Rules[0].pit_exit_penalty != '0'">{{ 'GENERAL.ON' | translate }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.COMPETITIVE_RL' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].competitive_rl == '0'">{{ 'GENERAL.NO' | translate }}</span>
                      <span *ngIf="league.Rules[0].competitive_rl != '0'">{{ 'GENERAL.YES' | translate }}</span>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="league.Rules[0].competitive_rl != '0'">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.MINIMAL_SAFETY' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].minimal_safety == '0'">U</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '1'">A</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '2'">B</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '3'">C</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '4'">D</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '5'">E</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '6'">F</span>
                      <span *ngIf="league.Rules[0].minimal_safety == '7'">S</span>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="league.Rules[0].competitive_rl != '0'">
                    <label class="col-sm-6 col-form-label">{{ 'RULES.MINIMAL_PLAYER_SKILL' | translate }}</label>
                    <div class="col-sm-6">
                      <span *ngIf="league.Rules[0].minimal_skill == '100'">100</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '200'">200</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '300'">300</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '400'">400</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '500'">500</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '600'">600</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '700'">700</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '800'">800</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '900'">900</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1000'">1000</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1100'">1100</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1200'">1200</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1300'">1300</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1400'">1400</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1500'">1500</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1600'">1600</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1700'">1700</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1800'">1800</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '1900'">1900</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '2000'">2000</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3000'">3000</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3100'">3100</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3200'">3200</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3300'">3300</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3400'">3400</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3500'">3500</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3600'">3600</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3700'">3700</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3800'">3800</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '3900'">3900</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4000'">4000</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4100'">4100</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4200'">4200</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4300'">4300</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4400'">4400</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4500'">4500</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4600'">4600</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4700'">4700</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4800'">4800</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '4900'">4900</span>
                      <span *ngIf="league.Rules[0].minimal_skill == '5000'">5000</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>