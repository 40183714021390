import { Component, OnInit } from '@angular/core';
import { Validation } from '../model/validation.model';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { GeneralComponent } from '../_utils/general/general.component';
import { CountryService } from '../services/country.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    constructor(
        private countryService: CountryService,
        private general: GeneralComponent,
        private userService: UserService,
        private router: Router,
    ) { }

    public maskPhone    = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

    forgot : {
        id: number,
        email: string,
        cellphone: string,
        code: string, 
        password: string
    } = {
        id: 0,
        email : '',
        cellphone : '',
        code: '',
        password: ''
    }
    validation: Validation = new Validation();
    loading: boolean = false;
    code: boolean = false;
    new_password: boolean = false;
    countries: any = [];
    country_ref: string = 'BR';
    inputPhone: any;

    ngOnInit() {    
        this.loadCountries();
    }

    geoIpLookup() {
        let url = window.location.href;
        if ( url.substring(0,5) == 'http:' ) {
            this.userService.get('http://ip-api.com/json').then((result: any) => {
                this.country_ref    = result.countryCode;
            });
        } else {
            this.userService.get('https://api.ipdata.co/?api-key=12f92c4691348b3209484cf14b90ca9a65d9faf296484626b7cf129f').then((result: any) => {
                this.country_ref    = result.country_code;
            });
        }
    }

    telInputObject(obj) {
        obj.setCountry(this.country_ref);
        this.inputPhone = obj;
    }

    loadCountries() {
        this.countryService.getCountries({}, '', '', 0, '', 10000000).then(rs => {
            this.countries  = rs;
            this.geoIpLookup();
        });
    }

    recoveryCode(){
        this.loading = true;
        this.validate('primary'); 
        if ( this.validation.valid ) {      
            this.forgot.cellphone = this.inputPhone.s.dialCode + this.forgot.cellphone;
            this.userService.getRecoveryCode(this.forgot.email, this.forgot.cellphone).then((ret:any) => {
                if(ret.summary.process == true){
                    this.code = true;
                    this.general.setMessage('GENERAL.FORGOT-CODE-SEND', 'success');
                    this.loading = false;
                }else{
                    this.loading = false;
                    //email/cellphone not found
                    this.general.setMessage('GENERAL.FORGOT-NOT-FOUND');
                }
            });
        } else {
            this.loading = false;
            this.general.setMessage('GENERAL.FORGOT-EMPTY-FIELDS');
        }
    }

    validate(type: string  = ''){    
        this.validation.fields      = [];

        if(type == 'code'){
            if(this.forgot.code == "") {
                this.validation.fields.push('forgot.code');
            } 
        }else if(type == 'primary'){
            if(this.forgot.email == "" && this.forgot.cellphone == "") {
                this.validation.fields.push('forgot.email');        
                this.validation.fields.push('forgot.cellphone');
            } 
        }else{
            if(this.forgot.password == "") {
                this.validation.fields.push('forgot.new_password');
            } 
        }

        if ( this.validation.fields.length > 0 )      
            this.validation.valid   = false;
        else
            this.validation.valid   = true;
        
    }

    checkCode() {
        this.loading  = true;
        this.validate('code')
        if ( this.validation.valid ) {
            this.userService.validateCode(this.forgot.email, this.forgot.cellphone, this.forgot.code).then(result => {
                if( (result.summary.process) && (result.summary.checked) ){
                    //new password field
                    this.code = false;
                    this.new_password = true;
                    this.forgot.id	= result.summary.id;
                    this.loading = false;
                } else {
                    this.general.setMessage('GENERAL.FORGOT-CODE-INVALID');
                    this.loading = false;
                }
            }, error => {
                this.general.setMessage(error);
                this.loading = false;
            });
        } else {
            this.loading  = false;
        }
    }

    changePassword() {
        this.loading  = true;
        this.validate()
        if ( this.validation.valid ) {
            this.userService.changePassword(this.forgot.id, this.forgot.password).then(result => {
                if( (result.summary.process) ){
                    this.loading = false;
                    this.router.navigate(['/login']);
                } else {
                    this.general.setMessage(result.summary.errorMessage);
                    this.loading = false;
                }
            }, error => {
                this.general.setMessage(error);
                this.loading = false;
            });
        } else {
            this.loading  = false;
        }
    }
  
    back() {
        this.router.navigate(['/login']);
    }
  
}
