import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthMainService } from '../services/auth.service';
import { GroupService } from '../services/group.service';
import { Router } from '@angular/router';
import { Login } from '../model/login.model';
import { GeneralComponent } from '../_utils/general/general.component';
import { UserService } from '../services/user.service';
import { Register } from '../model/register.model';
import { Validation } from '../model/validation.model';
import { CountryService } from '../services/country.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('password')loginPassElement:ElementRef;
    loading: boolean = false;
    showSteam: boolean = false;
    login: Login = new Login();
    validation: Validation = new Validation;
    register: Register = new Register();
    countries: any = [];
    country_ref: string = 'BR';
    inputPhone: any;

    public maskPhone    = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    public maskSteam    = [ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ];
    
    constructor(
        private authService: AuthMainService,
        private countryService: CountryService,
        private general: GeneralComponent,
        private groupService: GroupService,
        private router: Router,
        private userService: UserService,
    ){
        if(this.router.url == '/logout'){
            this.logout();
        }
    }

    geoIpLookup() {
        let url = window.location.href;
        if ( url.substring(0,5) == 'http:' ) {
            this.userService.get('http://ip-api.com/json').then((result: any) => {
                this.country_ref    = result.countryCode;
            });
        } else {
            this.userService.get('https://api.ipdata.co/?api-key=12f92c4691348b3209484cf14b90ca9a65d9faf296484626b7cf129f').then((result: any) => {
                this.country_ref    = result.country_code;
            });
        }
    }

    telInputObject(obj) {
        obj.setCountry(this.country_ref);
        this.inputPhone = obj;
    }

    ngOnInit() {
        const auth =  JSON.parse(localStorage.getItem('auth'));
        this.loadCountries();
        if(auth && auth.isLogged)
            this.router.navigate(['/app']);
    }

    loadCountries() {
        this.countryService.getCountries({}, '', '', 0, '', 10000000).then(rs => {
            this.countries  = rs;
            this.geoIpLookup();
        });
    }

    signInWithSteam(){
        // this.oauthService.redirectUri = window.location.origin + '/index.html';
        // this.oauthService.clientId = 'spa-demo';
        // this.oauthService.scope = 'openid profile email voucher';
        // this.oauthService.oidc = true;
        // this.oauthService.setStorage(sessionStorage);
        // this.oauthService.issuer = 'http://steamcommunity.com/openid';
        // this.oauthService.requireHttps = false;
        // this.oauthService.dummyClientSecret = 'geheim';
    
        // this.oauthService.loadDiscoveryDocument().then(() => {
        //   this.oauthService.tryLogin({});
        // });
    }
    
    // public login(): void {
    //     this.oauthService.clientId = 'spa-demo';
    //     this.oauthService.initImplicitFlow();
    // }

    signIn() {        
        this.loading = true;
        //Verificar se email existe para mostrar campo senha
        const verify = this.login.email.split("@")

        let filter = {};
        if(verify.length > 1){
            filter = {field: 'Users.email', value: this.login.email}
        }else{
            filter = {field: 'Users.login', value: this.login.email}
        }

        this.userService.getUsers([filter], '', '', 0, 'Users.email;Users.login').then(user => {
            if(user.summary.process && user.summary.total > 0){
                this.authService.unsetUser();
                this.authService.preLogin(this.login.email, this.login.password)
                    .then(response => {
                        if ( response.summary && ( response.summary.process == false || response.summary.message == 'error' ) ) {
                            this.general.setMessage('LOGIN.PASSWD-NOT-FOUND')
                            this.loading = false;
                        } else {
                            this.authService.login(this.login.email, this.login.password).then(response => {
                                localStorage.removeItem('mainUser')
                                localStorage.setItem('tokenUser', response.data.tokenUser);
                                localStorage.setItem('tokenUserExpires', response.data.tokenUserExpires);

                                let permissions = [];
                                if(response && response.data.permissions && response.data.permissions.length > 0)
                                    permissions = response.data.permissions

                                if(permissions.length == 0){
                                    
                                    localStorage.removeItem('preUser');
                                    localStorage.removeItem('tokenUser');
                                    localStorage.removeItem('tokenUserExpires');
                                    localStorage.removeItem('auth');
                                    localStorage.removeItem('sessionHash');
                                    localStorage.removeItem('avatar');
                                    localStorage.removeItem('lData');

                                    this.general.setMessage('LOGIN.USER-NOT-HAVE-ACCESS');
                                    this.loading = false;
                                    this.router.navigate(['/login']);
                                    return;
                                }
                                
                                permissions.push('users/change-password');
                                if ( permissions.length > 0 ) {
                                    this.authService.setUser(response.data, permissions).then(r => {
                                        if ( r ) {
                                            this.authService.getAvatar(response.data.id).then(result => {
                                                localStorage.setItem('sessionHash', response.data.sessionHash);
                                                this.general.setMessage('LOGIN.WELCOME', 'success');
                                                // this.router.navigate(['/']);
                                                window.location.href = '/';
                                                this.loading = false;
                                            });
                                        }
                                    });
                                } else {
                                    localStorage.removeItem('tokenUser');
                                    this.general.setMessage('LOGIN.USER-NOT-HAVE-ACCESS');
                                    this.loading = false;
                                }
                                
                            });
                        }
                },
                error => {
                    this.general.setMessage(<any>error);
                    this.loading = false
                });
            }else{
                // email|login não encontrado
                this.general.setMessage('LOGIN.USER-NOT-HAVE-ACCESS');
                this.loading    = false;
            }
        })
    }

    save() {
        this.loading = true;
        var filter = {field: 'Users.email', value: this.register.email}
        
        this.userService.getUsers([filter], '', '', 0, 'Users.email;Users.login').then(user => {
            if(user.summary.process && user.summary.total > 0){
                this.general.setMessage('REGISTER.USER-EXISTS');
                this.loading = false;
            } else {
                this.register.login  = this.register.email;
                this.validate(); 
                if ( this.validation.valid ) {
                    this.register.cellphone = this.inputPhone.s.dialCode + this.register.cellphone;
                    this.countryService.getCountries([{field: 'Country.phone_code', value: this.inputPhone.s.dialCode}]).then((rs: any) => {
                        if ( rs.items.length > 0 ) {
                            this.register.country   = rs.items[0].data.iso_code;
                        }
                    });
                    this.userService.insertUser(this.register).then(result => {
                        this.authService.unsetUser();
                        //adicionar racer group
                        if ( result.summary.process == false || result.summary.message == 'error' ) {
                            result.summary.errors.map((data:any) => {
                                this.general.apiErrors(data.code);
                            });
                            this.loading = false; 
                        } else {
                            this.groupService.insertGU({user_id: result.items.id, group_id: 10}).then(result => {
                                this.login.email    = this.register.email;
                                this.login.password = this.register.password;
                                this.signIn();
                            });
                        }
                    },
                    error => {
                        this.general.setMessage(<any>error);
                        this.loading = false
                    });
                } else {
                    this.general.setMessage(this.validation.messages);
                    this.loading    = false;
                }
            }
        })
    }


    validate(){
        this.validation.fields      = [];
        this.validation.messages    = [];

        if (!this.register.name) {
            this.validation.fields.push('register.name');
            this.validation.messages.push("ERROR.USER.NAME");
        }
        if (!this.register.email) {
            this.validation.fields.push('register.email');
            this.validation.messages.push("ERROR.USER.EMAIL");
        } else {
            if ( !this.validateEmailForm() ) {
                this.validation.fields.push('register.email');
                this.validation.messages.push("ERROR.USER.INVALID_EMAIL");
            }
        }
        if ( this.register.password && this.register.password != null ) {
            if ( this.register.password.length < 4 ) {
                this.validation.fields.push('register.password');
                this.validation.messages.push("ERROR.USER.PASSWORD_LENGTH");
            }
        } else {
            this.validation.fields.push('register.password');
            this.validation.messages.push("ERROR.USER.PASSWORD");
        }
        if (!this.register.cellphone) {
            this.validation.fields.push('register.cellphone');
            this.validation.messages.push("ERROR.USER.CELLPHONE");
        }
        if ( this.validation.messages.length > 0 )      
            this.validation.valid   = false;
        else
            this.validation.valid   = true;
    }

    validateEmailForm() {
        let test:boolean;
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        test = regexp.test(this.register.email);
        return test;
    }

    logout(){
        this.authService.unsetUser();
        // this.router.navigate(['/']);
        window.location.href = '/';
    }
}
