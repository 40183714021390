import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './_utils/breadcrumb/breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './_utils/loading/loading.component';
import { PaginationComponent } from './_utils/pagination/pagination.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask'; 
import { NgxCurrencyModule } from "ngx-currency";
import { CurrencyFormat } from './_utils/pipes/currency-pipe';
import { KeysPipe } from './_utils/pipes/key-pipe';
import { NumberOnlyDirective } from './_utils/directives/number.directive';
import { AlphanumericOnlyDirective } from './_utils/directives/alphanumeric.directive';
import { ConfirmationDialogComponent } from './_utils/confirmation/confirmation-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GoogleChartsModule } from 'angular-google-charts';
import { AgmCoreModule } from '@agm/core';
import { MyAccountMenuComponent } from './my-account/my-account-menu/my-account-menu.component';
import { TopBarComponent } from './my-account/top-bar/top-bar.component';
import { NgMarqueeModule } from 'ng-marquee';
import { StepComponent } from './_utils/step/step.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { EloComponent } from './_utils/elo/elo.component';
import { ChangedRatingComponent } from './_utils/changed-rating/rating.component';

@NgModule({
  declarations: [
    KeysPipe,
    BreadcrumbComponent,
    LoadingComponent,
    PaginationComponent,
    StepComponent,
    EloComponent,
    ChangedRatingComponent,
    CurrencyFormat,
    NumberOnlyDirective,
    AlphanumericOnlyDirective,
    ConfirmationDialogComponent,
    MyAccountMenuComponent,
    TopBarComponent
  ],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAZN9R44ir7pTarVJRTl5BzrHdeMTCBsTs'
    }),
    RouterModule,
    FormsModule,
    NgbModule,
    CarouselModule,
    Ng2TelInputModule,
    TranslateModule,
    TextMaskModule,
    NgxCurrencyModule,
    NgMarqueeModule,
    ReactiveFormsModule,
    AngularEditorModule,
    GoogleChartsModule
  ],
  exports:[
    BreadcrumbComponent,
    CarouselModule,
    TranslateModule,
    AgmCoreModule,
    RouterModule,
    FormsModule,
    LoadingComponent,
    PaginationComponent,
    StepComponent,
    EloComponent,
    ChangedRatingComponent,
    Ng2TelInputModule,
    NgMarqueeModule,
    NgbModule,
    TextMaskModule,
    NgxCurrencyModule,    
    CurrencyFormat,
    KeysPipe,
    NumberOnlyDirective,    
    AlphanumericOnlyDirective,
    ReactiveFormsModule,
    AngularEditorModule,
    GoogleChartsModule,
    MyAccountMenuComponent,
    TopBarComponent
  ],
  entryComponents:[
    ConfirmationDialogComponent
  ]
})
export class SharedModule { }