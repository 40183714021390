<div class="bg-dark">
    <div class="container white pt-4 pl-5 pl-md-3 pr-5 pr-md-3 pb-4">
        <div class="row">
            <div class="col-6 col-md-2 links">
                <ul class="list-unstyled">
                    <li class="separator">{{ 'GENERAL.COMMUNITY' | translate }}</li>
                    <li><a class="white" routerLink="/">{{ 'SITE.HOME' | translate }}</a></li>
                    <li><a class="white" routerLink="/organizers">{{ 'SITE.LEAGUES' | translate }}</a></li>
                    <li><a class="white" routerLink="/leagues">{{ 'SITE.CHAMPIONSHIPS' | translate }}</a></li>
                    <li><a class="white" routerLink="/races">{{ 'SITE.RACES' | translate }}</a></li>
                    <li><a class="white" routerLink="/drivers">{{ 'SITE.DRIVERS' | translate }}</a></li>
                    <li><a class="white" routerLink="/teams">{{ 'SITE.TEAMS' | translate }}</a></li>
                    <li><a class="white" routerLink="/ranking">{{ 'PAGES.RANKING' | translate }}</a></li>
                    <li><a class="white" href="https://store.theracersesports.com">{{ 'SITE.ECOMMERCE' | translate }}</a></li>
                    <!-- <li><a class="white" routerLink="/how-can-i-drive">{{ 'SITE.START-DRIVING' | translate }}</a></li> -->
                </ul>
            </div>
            <div class="col-6 col-md-3 links">
                <ul class="list-unstyled">
                    <li class="separator">{{ 'GENERAL.RESTRICTED-AREA' | translate }}</li>
                    <li><a class="white" routerLink="/my-leagues">{{ 'GENERAL.APP' | translate }}</a></li>
                    <li><a class="white" routerLink="/my-account">{{ 'GENERAL.MY-ACCOUNT' | translate }}</a></li>
                    <li class="separator mt-1">{{ 'GENERAL.MORE-INFO' | translate }}</li>
                    <li><a class="white" routerLink="/pages/terms">{{ 'PAGES.TERMS.NAME' | translate }}</a></li>
                    <li><a class="white" routerLink="/pages/privacy">{{ 'PAGES.PRIVACY' | translate }}</a></li>
                    <li><a class="white" routerLink="/pages/ranking">{{ 'PAGES.HOW-WORK-RANKING' | translate }}</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-3 offset-md-4 text-right">
                <div class="row">
                    <div class="col-12 mb-3">
                        <small><p><em>"{{ 'GENERAL.FREE_DESC' | translate }}"</em></p></small>
                        <a routerLink="/pages/donate"><img src="/assets/images/donate.gif"></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a href="https://www.facebook.com/theracersesports" target="_blank"><span class="fab fa-facebook fa-2x white"></span></a>&nbsp;
                        <a href="https://www.instagram.com/theracersesports" target="_blank"><span class="fab fa-instagram fa-2x white"></span></a>&nbsp;
                        <a href="https://wa.me/5541999897602" target="_blank"><span class="fab fa-whatsapp fa-2x white"></span></a>&nbsp;
                        <a href="https://discord.gg/6PKE6Uk" target="_blank"><span class="fab fa-discord fa-2x white"></span></a>&nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-light">
    <div class="container">
        <small>{{ 'GENERAL.ALL_RIGHTS_RESERVED' | translate }}</small> - 
        <small>{{ 'GENERAL.COPYRIGHT' | translate }}</small> - 
        <small>2020 - {{ 'GENERAL.TODAY' |  translate }}</small> - 
        <small><a routerLink="/pages/changelog">ChangeLog</a></small>
    </div>
</div>