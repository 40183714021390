import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';

import { AuthMainService } from './services/auth.service';
import { GeneralComponent } from './_utils/general/general.component';
import { TranslateService } from '@ngx-translate/core';
 
@Injectable()
export class AuthGuard implements CanActivate {

    errorMessage: any = [];

    constructor(
        private router: Router,
        private authService: AuthMainService,
        private general: GeneralComponent,
        private translate: TranslateService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        
    ): Observable<boolean> {
        let urlSplit = state.url.split('/');
        const comp = (urlSplit[2] == '' ? 'dashboard': urlSplit[2]);
        
        return this.authService.isLoggedIn
            .take(1)
            .map((isLoggedIn: boolean) => {
                if (!isLoggedIn){
                    this.router.navigate(['/login']);
                    return false;
                } else {
                    //check user permission
                    let auth                = JSON.parse(localStorage.getItem('auth'));
                    // let permissions         = auth.permissions;
                    let action              = next.routeConfig.path.split(':');
                    let tokenUserExpires    = localStorage.getItem('tokenUserExpires');
                    let d = new Date();
                    let month = d.getMonth() + 1;
                    let monthStr = '';
                    if (month < 10) {
                        monthStr = '0' + month.toString();
                    } else {
                        monthStr = month.toString();
                    }
                    let day = d.getDate();
                    let dayStr = '';
                    if (day < 10) {
                        dayStr = '0' + day.toString();
                    } else {
                        dayStr = day.toString();
                    }
                    let hour = d.getHours();
                    let hourStr = '';
                    if (hour < 10) {
                        hourStr = '0' + hour.toString();
                    } else {
                        hourStr = hour.toString();
                    }
                    let minute = d.getMinutes();
                    let minuteStr = '';
                    if (minute < 10) {
                        minuteStr = '0' + minute.toString();
                    } else {
                        minuteStr = minute.toString();
                    }
                    let second = d.getSeconds();
                    let secondStr = '';
                    if (second < 10) {
                        secondStr = '0' + second.toString();
                    } else {
                        secondStr = second.toString();
                    }
                    let now = d.getFullYear() + "-" + monthStr + '-' + dayStr + "T" + hourStr + ":" + minuteStr + ":" + secondStr + '-02:00';
                    
                    if ( tokenUserExpires > now ) {
                        if ( action[0].slice(-1) == '/' ) 
                            action[0]   = action[0].slice(0, -1)
                            
                        if(action[0] == null || action[0] == '' || comp === action[0])
                            action[0] = 'index';
                            
                        const compAction = comp + '/' + action[0];
                        //website routes
                        // permissions.push('subscription/index');
                        // permissions.push('create/index');
                        // permissions.push('request-participation/index');
                        // console.log(compAction);
                        // console.log(permissions);

                        // if ( action[0] != null && permissions && permissions.length > 0 && permissions.indexOf(compAction) == -1 ) {
                            // return false;
                        // } else {
                            // if ( auth.user.change_password == 'Y' ) {
                            //     if ( compAction != 'users/change-password' )
                            //         this.router.navigate(['/users/change-password']);
                            // }
                            return true;
                        // }
                    } else {
                        if ( this.router.url != '/login' ) {
                            if ( this.translate.langs.length == 0 ) {
                                this.translate.onLangChange.subscribe(() => {
                                    this.translate.get('GENERAL.LOGIN_ERROR.EXPIRED_USER_SESSION').subscribe(data => {
                                        this.authService.unsetUser();
                                        this.general.setMessage(data);
                                        this.router.navigate(['/login']);
                                    });
                                });
                            } else {
                                this.translate.get('GENERAL.LOGIN_ERROR.EXPIRED_USER_SESSION').subscribe(data => {
                                    this.authService.unsetUser();
                                    this.general.setMessage(data);
                                    this.router.navigate(['/login']);
                                });
                            }
                        }
                        return false;
                    }
                }
            }
        );
    }
}