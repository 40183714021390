import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from './_layouts/site-layout/topbar/topbar.component';
import { SiteFooterComponent } from './_layouts/site-layout/footer/footer.component';
import { SiteLayoutComponent } from './_layouts/site-layout/site-layout.component';
import { LoginComponent } from './login/login.component';
import { GeneralComponent } from './_utils/general/general.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';
import { NgMarqueeModule } from 'ng-marquee';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared.module';
import { AuthMainService } from './services/auth.service';
import { AuthGuard } from './auth.guard';
import { ConfirmationDialogService } from './_utils/confirmation/confirmation-dialog.service';
import { GroupsUsersService } from './services/groups_users.service';
import { FileService } from './services/file.service';
import { ImageService } from './services/image.service';
import { PermissionService } from './services/permission.service';
import { GroupService } from './services/group.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { UserService } from './services/user.service';
import { CurrencyFormat } from './_utils/pipes/currency-pipe';
import { ActivitiesService } from './services/activities.service';
import { LogService } from './services/log.service';
import { I18n, CustomDatepickerI18n } from './_utils/CustomDatePickerI18n';
import { NgbDatepickerI18n, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from './_utils/NgbDatePTParserFormatter';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SiteEmbedComponent } from './_layouts/site-embed/site-embed.component';

registerLocaleData(localePt, 'pt');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    // FooterComponent,
    TopbarComponent,
    SiteFooterComponent,
    // MainLayoutComponent,    
    SiteLayoutComponent,
    SiteEmbedComponent,
    LoginComponent,
    GeneralComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    ToasterModule.forRoot(),
    NgMarqueeModule,
    CarouselModule,
    Ng2TelInputModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        isolate: true,
    }),
    // AngularDualListBoxModule,
    SharedModule,
    AppRoutingModule
  ],
  providers: [
    AuthMainService,
    AuthGuard,
    ConfirmationDialogService,
    AuthMainService,
    GroupsUsersService,
    FileService, 
    ImageService,
    PermissionService,
    GroupService,
    DatePipe,
    UserService,
    GeneralComponent,
    CurrencyFormat,
    ToasterService,
    ActivitiesService,
    LogService,
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}]
  ],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
