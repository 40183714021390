import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class ImageService extends RestService {


    private _url = 'Images';
    
    constructor(protected _http: HttpClient) {
		super(_http);
	} 

    updateImage(image: any): any {
      let data = JSON.stringify(image);
      return this.putApi(this._url + '/' + image.id, data);
    }

    insertImage(reference: string, reference_id: number, file: File, unique: string = '0') : any {
        let formData:FormData = new FormData();
        formData.append('unique', unique);
        formData.append('reference', reference);
        formData.append('reference_id', reference_id.toString());
        formData.append('path', file, file.name);
        return this.postApi(this._url + '/send', formData);
    }

    resizeImage(url: string, width: number, height: number, entity: string, square: number = 0) : any {
        return this.getApi(this._url + '/resize', {params: {url: url, width: width, height: height, square: square, entity: entity}});
    }
    
    // getImages(filter_data: any, order_string: string = '-Images.id', page: number = 0, fields: string = '', limit: number = 20): any {
    getImages(filter_data: any, contain_string: string = '', order_string: any = '-Images.id', page: number = 0, fields: string = '', limit: number = 20, report:boolean = false, groupData: any = [], operationsData: any = [], unlimited = 0): any {
        let qs: string = '';
        let group: string = '';
        let operations: string = '';
        let paging: string = '';
        let order = order_string

        if ( filter_data != null && filter_data.length > 0 ) {
        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
            qs += item.field + '|' + item.value + ';'
            }else{
            qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })
        }
        
        if(groupData && groupData.length > 0){
        groupData.map(item => {        
            group += item.field + ';'        
        })
        }
        if(operationsData && operationsData.length > 0){
        operationsData.map(item => {        
            operations += item.operation + '|' + item.field + ';'        
        })
        }
        if(Array.isArray(order)){
        const orderArray = order
        order = ''
        orderArray.map(item => order += `${item.order}${item.field};`)
        }
        
        // return
        if(report == true)
        qs = filter_data;

        let offset = (page * limit);
        paging = offset + ";" + limit;
        // return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, fields: fields}});
        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order, contains: contain_string, fields: fields, group: group, operations: operations, unlimited: unlimited } });
    }

    deleteImage(id: number) : any {
        return this.deleteApi(this._url + '/' + id);
    }
}
