import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[AlphanumericOnly]'
})
export class AlphanumericOnlyDirective { 
    
    // private regex: RegExp = new RegExp(/^[a-zA-Z0-9 ]*$/g);
    // private regex: RegExp = new RegExp(/^[\w\s]*$/g);
    private specialKeys: Array<string> = [
        'Backspace', 'Tab', 'End', 'Home', '-', 'Delete', 'ArrowLeft', 'ArrowRight'
    ];
    private specialToRemove: Array<string> = [
        '"', '!', '@', '#', '$', '%', '&', '*', '(', ')', '-', '_', '+',
        '=', "'", '[', '{', 'ª', ']', '}', 'º', '¢', '¬', '£', ';', ':', 
        '<', ',', '.', '>', '?', '/', '§', '*', '|'
    ];
  
    constructor(private el: ElementRef) {}
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialToRemove.indexOf(event.key) !== -1) {
            event.preventDefault();
        }
    }
}