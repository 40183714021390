import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class FileService extends RestService {


    private _url = 'Files';

    private _sendUrl = 'Files/send';
    
    constructor(protected _http: HttpClient) {
		super(_http);
	} 

    insertFile(reference: string, reference_id: number, file: File, unique: string = '0', process: string = '0') : any {
        let formData:FormData = new FormData();
        formData.append('unique', unique);
        formData.append('process', process);
        formData.append('reference', reference);
        formData.append('reference_id', reference_id.toString());
        formData.append('path', file, file.name);
    
        return this.postApi(this._url + '/send', formData);
    }
    
    getFiles(filter_data: any, order_string: string = '-Files.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'Files.id|' + filter_data.id + ';';
        }
        if ( filter_data.src && filter_data.src != '' ) {
            qs   += 'Files.src|like|' + filter_data.src + ';';
        }
        if ( filter_data.status && filter_data.status != '' ) {
            qs   += 'Files.status|' + filter_data.status + ';';
        }
        if ( filter_data.entity && filter_data.entity != '' ) {
            qs   += 'Files.entity|' + filter_data.entity + ';';
        }
        if ( filter_data.entity_id && filter_data.entity_id != '' ) {
            qs   += 'Files.entity_id|' + filter_data.entity_id + ';';
        }
        
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, fields: fields}});
    }

    deleteFile(id: number) : any {
        return this.deleteApi(this._url + '/' + id);
    }
}
