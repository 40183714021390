import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SiteLayoutComponent } from './_layouts/site-layout/site-layout.component';
import { SiteEmbedComponent } from './_layouts/site-embed/site-embed.component';
import { PageLeagueEmbedComponent } from './leagues/leagues-embed/league-embed.component';

const routes: Routes = [
  // site layout routes
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { 
        path: '', 
        loadChildren: () => import('src/app/home/home.module').then(mod => mod.HomeModule), 
        data: { title: 'GENERAL.HOME' } 
      },
      { 
        path: 'organizers', 
        loadChildren: () => import('src/app/organizers/organizers.module').then(mod => mod.OrganizersModule),
      },
      { 
        path: 'leagues', 
        loadChildren: () => import('src/app/leagues/leagues.module').then(mod => mod.PageLeaguesModule),
      },
      { 
        path: 'drivers', 
        loadChildren: () => import('src/app/drivers/drivers.module').then(mod => mod.PageDriversModule),
      },
      { 
        path: 'races',
        loadChildren: () => import('src/app/races/races.module').then(mod => mod.RacesModule),
      },
      { 
        path: 'results',
        loadChildren: () => import('src/app/results/results.module').then(mod => mod.ResultsModule),
      },
      { 
        path: 'pages', 
        loadChildren: () => import('src/app/pages/pages.module').then(mod => mod.PagesModule),
      },
      { 
        path: 'my-account',
        loadChildren: () => import('src/app/my-account/my-account.module').then(mod => mod.MyAccountModule),
      },
      { 
        path: 'my-leagues', 
        loadChildren: () => import('src/app/my-league/my-league.module').then(mod => mod.MyLeagueModule),
      },
      { 
        path: 'cases', 
        loadChildren: () => import('src/app/cases/cases.module').then(mod => mod.CasesModule),
      },
      { 
        path: 'ranking', 
        loadChildren: () => import('src/app/ranking/ranking.module').then(mod => mod.RankingModule), 
      },
      { 
        path: 'teams', 
        loadChildren: () => import('src/app/teams/teams.module').then(mod => mod.PageTeamsModule), 
      },
      { 
        path: 'login', 
        component: LoginComponent, 
        data: { title: 'GENERAL.REGISTER' } 
      },
      { 
        path: 'logout', 
        component: LoginComponent, 
        data: { title: 'GENERAL.REGISTER' } 
      },
      { 
        path: 'forgot', 
        component: ForgotPasswordComponent, 
        data: { title: 'GENERAL.FORGOT' } 
      },
    ]
  },
  // no layout routes
  {
    path: 'e',
    component: SiteEmbedComponent,
    children: [
      { 
        path: 'view/:url', 
        component: PageLeagueEmbedComponent 
      },
    ]
  },
  // no layout routes
  { path: '**', redirectTo: '', data: { title: '' } }
];
@NgModule({
  imports: [
    RouterModule.forRoot(
    routes
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }