import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsService extends RestService {
  private _url = 'Results';
  private _urlCleanResult = 'Results/cleanResult';
  private _urlDriverStat = 'Results/driverStat';
  private _urlTeamStat = 'Results/teamStat';
  private _urlProcess = 'Results/processResult';
  private _urlFile = 'Results/insertResult';
  private _urlCategoriesResult = 'Results/processCategoriesResult';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertResult(result: any): any {
    let data = JSON.stringify(result);
    return this.postApi(this._url, data);
  }

  insertResultFile(items: any): any {
    let data = JSON.stringify(items);
    return this.postApi(this._urlFile, data);
  }

  deleteResult(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateResult(result: any): any {
    let data = JSON.stringify(result);
    return this.putApi(this._url + '/' + result.id, data);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data); 
  }

  driverStat(user_id: any) {
    return this.getApi(this._urlDriverStat, { params: { user_id: user_id } });
  }

  processResult(race_id: any, user_id: any, organizer_id: any, simulator_id: any, filename: any, session: any) {
    return this.getApi(this._urlProcess, { params: { race_id: race_id, user_id: user_id, organizer_id: organizer_id, simulator_id: simulator_id, filename: filename, session: session } });
  }
  
  processCategoriesResult(race_id: any, points_multiplier: any = 1): any {
    return this.getApi(this._urlCategoriesResult, { params: { race_id: race_id, points_multiplier: points_multiplier } });
  }

  teamStat(team_id: any) {
    return this.getApi(this._urlTeamStat, { params: { team_id: team_id } });
  }

  cleanResult(race_id: any) {
    return this.getApi(this._urlCleanResult, { params: { race_id: race_id } });
  }

  getResults(filter_data: any, contain_string: string = '', order_string: any = '+Results.id', page: number = 0, fields: string = '', limit: number = 20, report:boolean = false, groupData: any = [], operationsData: any = [], unlimited = 0): any {
    let qs: string = '';
    let group: string = '';
    let operations: string = '';
    let paging: string = '';
    let order = order_string

    if ( filter_data != null && filter_data.length > 0 ) {
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }
    if(groupData && groupData.length > 0){
      groupData.map(item => {        
           group += item.field + ';'        
      })
    }
    if(operationsData && operationsData.length > 0){
      operationsData.map(item => {        
           operations += item.operation + '|' + item.field + ';'        
      })
    }
    if(Array.isArray(order)){
      const orderArray = order
      order = ''
      orderArray.map(item => order += `${item.order}${item.field};`)
    }
    
    // return
    if(report == true)
       qs = filter_data;

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order, contains: contain_string, fields: fields, group: group, operations: operations, unlimited: unlimited } });
  }
}