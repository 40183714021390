<app-loading [loading]="loading"></app-loading>
<div class="container">
    <div class="row mt-5">
        <div class="col-12 col-md-8 offset-md-2 text-center">
            
            
            <h3 *ngIf="!code && !new_password">{{ 'FORGOT.FORGOT' | translate }}</h3>
            <p *ngIf="!code && !new_password">{{ 'FORGOT.INSTRUCTIONS' | translate }}</p>
            <!-- <div class="row" *ngIf="!code && !new_password">
                <div class="col-12 col-md-4 offset-md-4">
                    <div class="form-group">
                        <label for="">{{ 'GENERAL.EMAIL' | translate }}</label>
                        <input type="text" class="form-control" [disabled]="loading" [(ngModel)]="forgot.email" id="forgot" name="forgot" placeholder="E-mail" autofocus [ngClass]="validation.fields.indexOf('forgot.email') > -1 ? 'is-invalid' : ''">
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="!code && !new_password">
                <div class="col-12">
                    <strong>-- {{'GENERAL.OR' | translate}} --</strong>
                </div>
            </div> -->
            <div class="row" *ngIf="!code && !new_password">
                <div class="col-12 col-md-4 offset-md-4">
                    <div class="form-group">
                        <label for="">{{ 'GENERAL.CELLPHONE' | translate }}</label>
                        <!-- <input type="tel" [textMask]="{mask: maskPhone}" [disabled]="loading" [(ngModel)]="forgot.cellphone" id="cellphone" name="cellphone" class="form-control" placeholder="(99) 9 9999-9999" [ngClass]="validation.fields.indexOf('forgot.cellphone') > -1 ? 'is-invalid' : ''"> -->
                        <input type="text" ng2TelInput [(ngModel)]="forgot.cellphone" 
                        [ng2TelInputOptions]="{initialCountry: country_ref}"
                        (intlTelInputObject)="telInputObject($event)"
                        [disabled]="loading" name="phone" class="form-control" 
                        [ngClass]="validation.fields.indexOf('forgot.cellphone') > -1 ? 'is-invalid' : ''"
                        [textMask]="{mask: maskPhone, placeholderChar: ' '}" />
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!code && !new_password">
                <div class="col-12 col-md-4 offset-md-4 text-center">
                    <input type="submit" (click)="recoveryCode()" class="btn btn-primary mr-1" value="{{'GENERAL.RECOVERY' | translate}}">
                    <input type="button" (click)="back()" class="btn btn-primary" value="{{'GENERAL.BACK' | translate}}">
                </div>
            </div>


            <h3 *ngIf="code">{{ 'GENERAL.FORGOT-CODE-TITLE' | translate }}</h3>
            <p *ngIf="code">{{ 'GENERAL.FORGOT-CODE-INSTRUCTIONS' | translate }}</p>
            <div class="row" *ngIf="code">
                <div class="col-12 col-md-4 offset-md-4">
                    <div class="form-group">
                        <label for="">{{ 'GENERAL.FORGOT-CODE' | translate }}</label>
                        <input type="tel" class="form-control" [disabled]="loading" [(ngModel)]="forgot.code" id="code" name="code" placeholder="Code" autofocus [ngClass]="validation.fields.indexOf('forgot.code') > -1 ? 'is-invalid' : ''">
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="code">
                <div class="col-12 col-md-4 offset-md-4 text-center">
                    <input type="submit" (click)="checkCode()" class="btn btn-primary mr-1" value="{{'GENERAL.CHECK-CODE' | translate}}">
                    <input type="button" (click)="back()" class="btn btn-primary" value="{{'GENERAL.BACK' | translate}}">
                </div>
            </div>

            <h3 *ngIf="!code && new_password">{{ 'GENERAL.FORGOT-NEW-PASSWORD' | translate }}</h3>
            <p *ngIf="!code && new_password">{{ 'GENERAL.FORGOT-PASSWORD-INSTRUCTIONS' | translate }}</p>
            <div class="row" *ngIf="!code && new_password">
                <div class="col-12 col-md-4 offset-md-4">
                    <div class="form-group">
                        <label for="">{{ 'GENERAL.PASSWORD' | translate }}</label>
                        <input type="password" class="form-control" [disabled]="loading" [(ngModel)]="forgot.password" id="password" name="password" placeholder="{{ 'GENERAL.PASSWORD' | translate}}" autofocus [ngClass]="validation.fields.indexOf('forgot.code') > -1 ? 'is-invalid' : ''">
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!code && new_password">
                <div class="col-12 col-md-4 offset-md-4 text-center">
                    <input type="submit" (click)="changePassword()" class="btn btn-primary mr-1" value="{{'GENERAL.CHANGE-PASSWORD' | translate}}">
                    <input type="button" (click)="back()" class="btn btn-primary" value="{{'GENERAL.BACK' | translate}}">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8 offset-md-2 mb-3 mt-2">
            <div class="card">
                <div class="card-body bg-light">
                    <small>
                        <em>- {{'LOGIN.P1' | translate}}<br />
                            - {{'LOGIN.P2' | translate}}<br />
                            - {{'LOGIN.P3' | translate}} <a routerLink="/pages/privacy">{{'LOGIN.P4' | translate}}</a>
                        </em>
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-loading [loading]="loading"></app-loading>


 -->
