import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { ToasterService, Toast } from 'angular2-toaster';
import { BodyOutputType } from 'angular2-toaster';
import { Subject } from 'rxjs/Subject';


@Component({
    selector: 'my-app',
    template: '<ng-content></ng-content>'
})
@Injectable()
export class GeneralComponent implements OnInit {

    globalMessage: {
        error: [],
        success: []
    } = {
            error: [],
            success: []
        };
    errorMessage: any = [];
    successMessage: any = [];


    itemValue = new Subject();


    constructor(
        private translate: TranslateService,
        private toaster: ToasterService
    ) { }

    ngOnInit() {
    }

    set theItem(value) {
        this.itemValue.next(value); // this will make sure to tell every subscriber about the change.
        localStorage.setItem('updateNotifications', value);
    }

    get theItem() {
        return localStorage.getItem('updateNotifications');
    }

    setUrl(type = 'link', string) {
        if ( string && string != null && string != '' ) {
            if ( type == 'link' ) {
                var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
                string  = string.replace(exp,"$1"); 
                if ( string.substring(0,4) != 'http' ) {
                    string  = 'http://' + string;
                }
            }
            if ( type == 'discord' ) {
                if ( string.substring(0,5) != 'https' ) {
                    string  = 'https://' + string;
                }
            }
            if ( type == 'facebook' ) {
                if ( ( string.substring(0,25) != 'https://www.facebook.com/' ) && 
                ( string.substring(0,21) != 'https://facebook.com/' ) &&
                ( string.substring(0,13) != 'facebook.com/' ) && 
                ( string.substring(0,15) != 'm.facebook.com/' ) && 
                ( string.substring(0,23) != 'https://m.facebook.com/' ) ) {
                    string  = 'https://www.facebook.com/' + string;
                }
                if ( string.substring(0,13) == 'facebook.com/' ) {
                    string  = 'https://www.facebook.com/' + string.substring(13);
                }
                if ( string.substring(0,15) == 'm.facebook.com/' ) {
                    string  = 'https://www.facebook.com/' + string.substring(15);
                }
                if ( string.substring(0,23) == 'https://m.facebook.com/' ) {
                    string  = 'https://www.facebook.com/' + string.substring(21);
                }
            }
            if ( type == 'instagram' ) {
                if ( string.substring(0,1) == '@' ) {
                    string  = string.substring(1);
                }
                if ( ( string.substring(0,26) != 'https://www.instagram.com/' ) &&
                    ( string.substring(0,22) != 'https://instagram.com/' ) ) {
                    string  = 'https://www.instagram.com/' + string;
                }
            }
        }
        return string
    }

    convertDateString(stringDate, separator: string = '-', reverse: boolean = false, hour: boolean = false) {
        if (stringDate == null || stringDate == '') {
            return stringDate
        } else {
            let firstExploded   = '';
            let separatorTo = (separator == '-') ? '/' : '-';
            if (reverse) {
                let exploded = stringDate.split(separator);
                if ( hour == true ) {
                    firstExploded = stringDate.split(' ');
                    exploded = firstExploded[0].split(separator);
                }
                if (exploded.length == 1)
                    return stringDate;
                else {
                    if ( hour == true ) {
                        return exploded[2] + separatorTo + exploded[1] + separatorTo + exploded[0] + ' ' + firstExploded[1];
                    } else {
                        return exploded[2] + separatorTo + exploded[1] + separatorTo + exploded[0];
                    }
                }
            } else {
                let exploded = stringDate.split(separator);
                if ( hour == true ) {
                    firstExploded = stringDate.split(' ');
                    exploded = firstExploded[0].split(separator);
                }
                if (exploded.length == 1)
                    return stringDate;
                else {
                    if ( hour == true ) {
                        return exploded[2] + separatorTo + exploded[1] + separatorTo + exploded[0] + ' ' + firstExploded[1];
                    } else {
                        return exploded[2] + separatorTo + exploded[1] + separatorTo + exploded[0];
                    }
                }
            }
        }
    }

    msToTime(duration: number, type: number = 1) {

        if ( type == 1 ) {
            let milliseconds = parseInt((duration % 1000).toString());
            let seconds = parseInt(((duration / 1000) % 60).toString());
            let minutes = parseInt(((duration / (1000 * 60) ) % 60).toString());
            
            let strSeconds = (seconds < 10) ? "0" + seconds.toString() : seconds.toString();
            let strMiliSeconds  = milliseconds.toString();
            if ( milliseconds < 10 )  {
                strMiliSeconds  = "00" + milliseconds.toString();
            }
            if ( ( milliseconds > 9 ) && ( milliseconds < 100 ) ) {
                strMiliSeconds  = "0" + milliseconds.toString();
            }
            if ( milliseconds == 0 ) {
                strMiliSeconds  = "000";
            }
            return minutes + ":" + strSeconds + "." + strMiliSeconds;
        } else if ( type == 3 ) {
            let remain = duration * 1000
            let days = Math.floor(remain / (1000 * 60 * 60 * 24))
            remain = remain % (1000 * 60 * 60 * 24)

            let hours = Math.floor(remain / (1000 * 60 * 60))
            remain = remain % (1000 * 60 * 60)

            let minutes = Math.floor(remain / (1000 * 60))
            remain = remain % (1000 * 60)

            let seconds = Math.floor(remain / (1000))
            remain = remain % (1000)

            let milliseconds = Math.floor(remain);

            let strSeconds = (seconds < 10) ? "0" + seconds.toString() : seconds.toString();
            let strMiliSeconds  = milliseconds.toString();
            if ( milliseconds < 10 )  {
                strMiliSeconds  = "00" + milliseconds.toString();
            }
            if ( ( milliseconds > 9 ) && ( milliseconds < 100 ) ) {
                strMiliSeconds  = "0" + milliseconds.toString();
            }
            if ( milliseconds == 0 ) {
                strMiliSeconds  = "000";
            }
            return minutes + ":" + strSeconds + "." + strMiliSeconds;
        } else {
            let seconds = parseInt(((duration) % 60).toString());
            let minutes = parseInt(((duration / (60) ) % 60).toString());
            let hours = parseInt(((duration / (60 * 60) ) % 60).toString());
            
            let strSeconds = (seconds < 10) ? "0" + seconds.toString() : seconds.toString();
            let strHours = (hours < 10) ? "0" + hours : hours.toString();
            let strMinutes = (minutes < 10) ? "0" + minutes : minutes.toString();
            return strHours + ':' + strMinutes + ":" + strSeconds;
        }
    }

    monthDiff(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    getMessage(type = 'error') {

        if (localStorage.getItem('globalMessage')) {
            this.globalMessage = JSON.parse(localStorage.getItem('globalMessage'));
            localStorage.removeItem('globalMessage');
        }
        if (type == 'error') {
            let check = '';
            this.translate.get('GENERAL.CHECK').subscribe(data => {
                check = data;
            });
            let finalMessage = '';
            this.globalMessage.error.map(msg => {
                this.translate.get(msg).subscribe(data => {
                    if (data instanceof Object) {
                        let values = Object.keys(data).map(key => data[key]);
                        values.map(item => {
                            finalMessage += item + '<br />';
                        });
                    } else {
                        finalMessage = data;
                    }
                });
            });
            let config = {
                animation: 'fade',
                type: 'error',
                title: check,
                body: finalMessage,
                // tapToDismiss: true,
                bodyOutputType: BodyOutputType.TrustedHtml
            }
            this.toaster.pop(config);
        } else if (type == 'warning') {
            let finalMessage = '';
            this.globalMessage.error.map(msg => {
                this.translate.get(msg).subscribe(data => {
                    if (data instanceof Object) {
                        let values = Object.keys(data).map(key => data[key]);
                        values.map(item => {
                            finalMessage += item + '<br />';
                        });
                    } else {
                        finalMessage = data;
                    }
                });
            });
            let config = {
                animation: 'fade',
                type: 'warning',
                title: '',
                // tapToDismiss: true,
                body: finalMessage,
                bodyOutputType: BodyOutputType.TrustedHtml
            }
            this.toaster.pop(config);
        } else {
            let finalMessage = '';
            this.globalMessage.success.map(msg => {
                this.translate.get(msg).subscribe(data => {
                    if (data instanceof Object) {
                        let values = Object.keys(data).map(key => data[key]);
                        values.map(item => {
                            finalMessage += item + '\r\n';
                        });
                    } else {
                        finalMessage = data;
                    }
                });
            });
            let config = {
                animation: 'fade',
                type: 'success',
                title: '',
                // timeout: 0,
                // tapToDismiss: true,
                body: finalMessage,
                bodyOutputType: BodyOutputType.TrustedHtml
            }
            this.toaster.pop(config);
        }
    }

    setMessage(message: any = '', type = 'error') {
        if (localStorage.getItem('globalMessage'))
            this.globalMessage = JSON.parse(localStorage.getItem('globalMessage'));

        this.errorMessage = [];
        this.successMessage = [];
        if (type == 'error') {
            this.errorMessage.push(message);
        } else {
            this.successMessage.push(message);
        }
        this.globalMessage.success = this.successMessage;
        this.globalMessage.error = this.errorMessage;
        localStorage.setItem('globalMessage', JSON.stringify(this.globalMessage));
        return this.getMessage(type);
    }

    apiErrors(data) {
        this.setMessage('ERRORS.CODE.' + data);
    }

    ptDate(date) {
        var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])      [\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;

        if (!((date.match(RegExPattern)) && (date != '')))
            return false
        else
            return true

    }

    show(value, type) {
        if ( type == 'conclusion_date' ) {
            let dateArr = value.split("-");
            let sem = '02';
            if ( dateArr[1] < 7 )
                sem = '01';
            return dateArr[0] + '.' + sem;
        } else if ( type = 'graduation_date' ) {
            let dateArr = value.split("-");
            return dateArr[1] + '/' + dateArr[0];
        }
    }
}