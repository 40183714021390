import { Component, OnInit, HostListener  } from '@angular/core';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
    documentClick(event) {
        // var clickedComponent = event.target;
        // var sidebar = document.querySelector('#sidebar');
        
        // if(
        //   clickedComponent.className != 'mdi mdi-menu' && 
        //   clickedComponent.className != 'nav-link ng-star-inserted' && 
        //   clickedComponent.className != 'menu-title' && 
        //   clickedComponent.className != 'sidebar sidebar-offcanvas active'
        // ){
        //   // sidebar.classList.remove('active')
        // }else if(clickedComponent.className == 'mdi mdi-menu' && !sidebar.classList.contains("active")){
        //   sidebar.classList.add('active')
        // }else if(clickedComponent.className == 'mdi mdi-menu' && sidebar.classList.contains("active")){
        //   sidebar.classList.remove('active')
        // }
    }

}
