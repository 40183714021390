import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { LeagueService } from 'src/app/services/league.service';
import { ImageService } from 'src/app/services/image.service';
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { LeaguesUsersService } from 'src/app/services/leagues-users.servic';
import { FileService } from 'src/app/services/file.service';
import { UserService } from 'src/app/services/user.service';
import { TeamService } from 'src/app/services/team.service';
import { ResultsService } from 'src/app/services/result.service';
import { ResultsPunitionsService } from 'src/app/services/results-punition.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-league-embed",
  templateUrl: "./league-embed.component.html",
  styleUrls: ["./league-embed.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PageLeagueEmbedComponent implements OnInit {
  tab: string = 'classification_drivers';
  url: string = '';
  league: any;
  today: Date = new Date();
  actual_img_banner: string = '';
  img_id_full_banner: number;
  img_found_full_banner: boolean = false;
  actual_img: string = '';
  img_id: number;
  img_found: boolean = false;
  loading: boolean = false;
  standings: any = [];
  standingsT: any = [];
  color: any = [];
  mode: string = 'reduced';
  modeT: string = 'reduced';
  now: Date = new Date;
  subscribed: number = 0;
  regulation_file: string = '';
  regulation_file_url: string = '';
  driversWinners: any = [];
  teamsWinners: any = [];
  class_id: any;
  classes: any = [];
  category: string = '';
  categories: any = [];
  popover: any = [];
  listDrivers: any = [];
  summary: any = [];
  auth: any;
  readMore: boolean = false;
  subscription: any = false;
  config: any = {
    top_banner: 0,
    header: 0,
    description: 0,
    show_calendar: 0,
    show_subscribers: 0,
    show_classification_drivers: 0,
    show_classification_teams: 0,
    show_rules: 0,
    show_modes: 'all', //all/minimal/reduced/complete
    show_rating: 0,
    show_menu: 0
  }

  constructor(
    private general: GeneralComponent,
    private modalService: NgbModal,
    private fileService: FileService,
    private imageService: ImageService,
    private _route: ActivatedRoute,
    private leagueService: LeagueService,
    private leaguesUsersService: LeaguesUsersService,
    private resultsService: ResultsService,
    private resultsPunitionsService: ResultsPunitionsService,
    private teamsService: TeamService,
    private usersService: UserService
  ) {}

  ngOnInit() {
    this.loading  = true;

    this.config.top_banner  = ( this._route.snapshot.queryParams.top_banner ) ? this._route.snapshot.queryParams.top_banner : 0;
    this.config.header  = ( this._route.snapshot.queryParams.header ) ? this._route.snapshot.queryParams.header : 0;
    this.config.description  = ( this._route.snapshot.queryParams.description ) ? this._route.snapshot.queryParams.description : 0;
    
    this.config.show_calendar  = ( this._route.snapshot.queryParams.show_calendar ) ? this._route.snapshot.queryParams.show_calendar : 0;
    this.config.show_subscribers  = ( this._route.snapshot.queryParams.show_subscribers ) ? this._route.snapshot.queryParams.show_subscribers : 0;
    this.config.show_classification_drivers  = ( this._route.snapshot.queryParams.show_classification_drivers ) ? this._route.snapshot.queryParams.show_classification_drivers : 0;
    this.config.show_classification_teams  = ( this._route.snapshot.queryParams.show_classification_teams ) ? this._route.snapshot.queryParams.show_classification_teams : 0;
    this.config.show_rules  = ( this._route.snapshot.queryParams.show_rules ) ? this._route.snapshot.queryParams.show_rules : 0;

    let count_menu  = 0;
    if ( this.config.show_calendar == 1 ) 
      count_menu++;
    if ( this.config.show_subscribers == 1 ) 
      count_menu++;
    if ( this.config.show_classification_drivers == 1 ) 
      count_menu++;
    if ( this.config.show_classification_teams == 1 ) 
      count_menu++;
    if ( this.config.show_rules == 1 ) 
      count_menu++;
    
    if ( count_menu > 1 ) {
      this.config.show_menu = 1;
    }
    this.config.show_modes  = ( this._route.snapshot.queryParams.show_modes ) ? this._route.snapshot.queryParams.show_modes : 'all';
    this.config.show_rating  = ( this._route.snapshot.queryParams.show_rating ) ? this._route.snapshot.queryParams.show_rating : 0;
    if ( this.config.show_modes != 'all' ) {
      this.mode = this.config.show_modes;
    }
    //colors
    this.color[0] = 'color1';
    this.color[1] = 'color2';
    this.color[2] = 'color1';
    this.color[3] = 'color2';
    this.color[4] = 'color1';
    this.color[5] = 'color2';
    this.color[6] = 'color1';
    this.color[7] = 'color2';
    this.color[8] = 'color1';
    this.color[9] = 'color2';
    this.color[10] = 'color1';
    this.color[11] = 'color2';
    this.color[12] = 'color1';
    this.color[13] = 'color2';
    this.color[14] = 'color1';
    this.color[15] = 'color2';
    this.color[16] = 'color1';
    this.color[17] = 'color2';
    this.color[18] = 'color1';
    this.color[19] = 'color2';
    this.color[20] = 'color1';
    this.color[21] = 'color2';
    this.color[22] = 'color1';
    this.color[23] = 'color2';
    this.url = <any>this._route.snapshot.paramMap.get('url');
    this.auth =  JSON.parse(localStorage.getItem('auth'));
    this.load();
  }

  load(){
    this.leagueService.getLeagues([{field: 'Leagues.url', value: this.url}], 'Platforms;ActiveLeaguesUsers;Avatar;Users;Simulators;Races.ActiveRacesUsers;Races.VehiclesClasses;Races.VehiclesClasses1;Races.VehiclesClasses2;Races.VehiclesClasses3;Races.VehiclesClasses4;Races.VehiclesClasses5;Races.VehiclesClasses6;Races.Vehicles;Races.Tracks;Points;Rules;Realisms;Organizers').then(rs => {
      this.league = rs.items[0].data;
      if ( this.league.general_category == '1' ) {
        this.category = 'GENERAL';
      }
      if ( this.league.categories != null && this.league.categories != '' ) {
        this.categories	= this.league.categories.split(',');
        if ( localStorage.getItem('league_view_' + this.league.id) )
          this.category	= JSON.parse(localStorage.getItem('league_view_' + this.league.id));
      }
      if ( this.league.general_category != '1' && ( this.category == '' || this.category == null ) && this.categories.length > 0 ) {
        this.category = this.categories[0].trim();
      }
      this.loadImage();
      this.loadBanner();
      if ( this.league.description && this.league.description != '' ) {
        this.league.description = this.league.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
        var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        this.league.description  = this.league.description.replace(exp,"<a target='_blank' href='$1'>$1</a>"); 
      }
      if (
        ( this.league.subscription_start != null ) &&
        ( this.league.subscription_end != null ) &&
        ( new Date(this.league.subscription_start) < this.today ) && 
        ( new Date(this.league.subscription_end) > this.today )
      ) {
        this.league.opened = '1';
      } else {
        this.league.opened = '0';
      }
      if ( this.league.subscription_type == 'open' ) {
        this.tab = 'calendar';
      }
      let classes = [];
      if ( this.league.Races.length > 0 ) {
        this.league.Races.map((item, i) => {
          if ( item.vehicles_classes_id == -2 ) {
            if ( item.vehicles_classes_id1 != 0 ) {
              if ( classes.indexOf(item.vehicles_classes_id1) == -1 ) {
                classes.push(item.vehicles_classes_id1);
                this.classes.push(item.VehiclesClasses1);
              }
            }

            if ( item.vehicles_classes_id2 != 0 ) {
              if ( classes.indexOf(item.vehicles_classes_id2) == -1 ) {
                classes.push(item.vehicles_classes_id2);
                this.classes.push(item.VehiclesClasses2);
              }
            }

            if ( item.vehicles_classes_id3 != 0 ) {
              if ( classes.indexOf(item.vehicles_classes_id3) == -1 ) {
                classes.push(item.vehicles_classes_id3);
                this.classes.push(item.VehiclesClasses3);
              }
            }

            if ( item.vehicles_classes_id4 != 0 ) {
              if ( classes.indexOf(item.vehicles_classes_id4) == -1 ) {
                classes.push(item.vehicles_classes_id4);
                this.classes.push(item.VehiclesClasses4);
              }
            }

            if ( item.vehicles_classes_id5 != 0 ) {
              if ( classes.indexOf(item.vehicles_classes_id5) == -1 ) {
                classes.push(item.vehicles_classes_id5);
                this.classes.push(item.VehiclesClasses5);
              }
            }

            if ( item.vehicles_classes_id5 != 0 ) {
              if ( classes.indexOf(item.vehicles_classes_id6) == -1 ) {
                classes.push(item.vehicles_classes_id6);
                this.classes.push(item.VehiclesClasses6);
              }
            }

            this.class_id = item.vehicles_classes_id1;
          } else {
            this.class_id = item.vehicles_classes_id;
          }
          item.past = true;
          if ( new Date(item.datetime) > this.now )
            item.past = false;
          
          let filter0  = [
            {field: 'Results.position', value: 1},
            {field: 'Results.races_id', value: item.id}
          ]
          this.resultsService.getResults(filter0, 'VehiclesClasses;RacesUsers.Users;LeaguesUsers.Users').then(rs => {
            item.winners  = rs.items;
          })
          let filter1  = [
            {field: 'Results.pole_position', value: 1},
            {field: 'Results.races_id', value: item.id}
          ]
          this.resultsService.getResults(filter1, 'VehiclesClasses;RacesUsers.Users;LeaguesUsers.Users').then(rs => {
            item.poles  = rs.items;
          })
          let filter2  = [
            {field: 'Results.fastest_lap', value: 1},
            {field: 'Results.races_id', value: item.id}
          ]
          this.resultsService.getResults(filter2, 'VehiclesClasses;RacesUsers.Users;LeaguesUsers.Users').then(rs => {
            item.fastlaps  = rs.items;
          })
        })
        this.checkSubscriptions();
        this.loadAttachments();
        this.loadDrivers();
        this.loadSubscriptions();
      } else {
        this.checkSubscriptions();
        this.loadAttachments();
        this.loadDrivers();
        this.loadSubscriptions();
      }
      if ( this.league.started == '2' ) {
        let winners   = [this.league.winner_id, this.league.second_id, this.league.third_id];
        this.usersService.getUsers([{field: 'Users.id', operator: 'IN', value: winners}], 'Avatar').then(rs => {
          for ( let i = 0; rs.items[i]; i++ ) {
            if ( rs.items.length > 0 && rs.items[i].data.Avatar && rs.items[i].data.Avatar.src != '' ) {
              this.imageService.resizeImage(rs.items[i].data.Avatar.src, 100, null, 'avatar', 1).then(result => {
                if ( result.summary.process == false || result.summary.message == 'error' ) {
                    this.loading  = false;
                } else {
                    rs.items[i].data.Avatar.actual_img = result.summary.full_url;
                    this.loading  = false;
                }
              },
              error => {
                this.general.setMessage(<any>error); 
                this.loading = false
              });
            }
            if ( rs.items[i].data.id == this.league.winner_id )
              this.driversWinners[0]  = rs.items[i].data;
            if ( rs.items[i].data.id == this.league.second_id )
              this.driversWinners[1]  = rs.items[i].data;
            if ( rs.items[i].data.id == this.league.third_id )
              this.driversWinners[2]  = rs.items[i].data;
          }
        });
        // if ( this.league.teams_standings == '1' ) {
          let winnersT  = [this.league.winner_team_id, this.league.second_team_id, this.league.third_team_id];
          this.teamsService.getTeams([{field: 'Teams.id', operator: 'IN', value: winnersT}], 'Avatar').then(rs => {
            for ( let i = 0; rs.items[i]; i++ ) {
              if ( rs.items.length > 0 && rs.items[i].data.Avatar && rs.items[i].data.Avatar.src != '' ) {
                this.imageService.resizeImage(rs.items[i].data.Avatar.src, 100, 100, 'team_logo', 1).then(result => {
                  if ( result.summary.process == false || result.summary.message == 'error' ) {
                      this.loading  = false;
                  } else {
                      rs.items[i].data.Avatar.actual_img = result.summary.full_url;
                      this.loading  = false;
                  }
                },
                error => {
                  this.general.setMessage(<any>error); 
                  this.loading = false
                });
              }
              if ( rs.items[i].data.id == this.league.winner_team_id )
                this.teamsWinners[0]  = rs.items[i].data;
              if ( rs.items[i].data.id == this.league.second_team_id )
                this.teamsWinners[1]  = rs.items[i].data;
              if ( rs.items[i].data.id == this.league.third_team_id )
                this.teamsWinners[2]  = rs.items[i].data;
            }
          });
        // }
      }
      if ( this.league.teams_standings == '1' )
        this.loadTeams();
    });
  }

	changeCategory(category){
		this.category	= category.trim();
    localStorage.setItem('league_' + this.league.id, JSON.stringify(this.category));
    this.loadDrivers();
    if ( this.league.teams_standings == '1' )
      this.loadTeams();
	}

  changeClass(class_id) {
    this.class_id = class_id;
    this.loadDrivers();
    if ( this.league.teams_standings == '1' )
      this.loadTeams();
  }

  checkSubscriptions(){
    this.leaguesUsersService.getLeaguesUsers([
      {field: 'LeaguesUsers.leagues_id', value: this.league.id},
      {field: 'LeaguesUsers.status', operator: 'IN', value: 'a,i,w,c'}
    ], '', '', 0, '', null, false, [{field: 'LeaguesUsers.users_id'}]).then(rs => {
      this.subscribed = rs.summary.total;
      rs.items.map((subscription: any) => {
        if ( subscription.data.users_id == this.auth.user.id ) {
          // console.log(subscription);
        }
      })
    })
  }

  subscribedInfo(content, event){
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {}, (reason) => {});
  }

  loadAttachments() {
      this.loading  = true;
      this.fileService.getFiles({entity: 'regulation-league', entity_id: this.league.id}, '', 0).then(result => {
          this.regulation_file = '';
          if ( result.summary.total > 0 ) {
              let config =  JSON.parse(localStorage.getItem('config'));
              let file_final  = result.items[0].data.src.split('/')
              this.regulation_file = file_final[file_final.length - 1];
              this.regulation_file_url = config['api_url'] + '/' + result.items[0].data.src;
          }
          this.loading  = false;
      });
  }

  loadSubscriptions(){
    this.loading  = true;
    this.leaguesUsersService.getLeaguesUsers([
        {field: 'LeaguesUsers.leagues_id', value: this.league.id},
        {field: 'LeaguesUsers.status', operator: 'IN', value: 'a,i,w,c'}
    ], 'Users;Teams;Vehicles;LeaguesUsersSlots.Users', '+LeaguesUsers.status,+Users.name', 0, '', 999999).then(result => {
        this.listDrivers    = [];
        if ( result.summary.total > 0 ) {
            this.listDrivers = result.items;
            this.listDrivers.map((item: any) => {
                if ( this.summary[item.data.status] )
                    this.summary[item.data.status]++;
                else
                    this.summary[item.data.status]  = 1;
            });
        }
        this.loading  = false;
    })
  }

  loadDrivers(){
    this.loading    = true;
    this.standings  = [];
    let category    = '';
    if ( this.category.trim() != '' )
      category  = this.category.trim();
    if ( this.category == 'GENERAL')
      category  = 'NULL';

    this.leagueService.standings(this.league.id, this.class_id, category).then(rs => {
      if ( rs.items.length > 0 ) {
        rs.items.map((st: any) => {
          st.Races.map((race: any) => {
            if ( race.Result && race.Result[0] ) {
              this.popover[race.Result[0].id] = false;
              this.resultsPunitionsService.getResultsPunitions([{field: 'ResultsPunitions.results_id', value: race.Result[0].id}]).then((punitions: any) => {
                if ( punitions.items.length > 0 ) {
                  race.Result[0].punitions  = punitions.items;
                }
              });
            }
          });
          if ( st.country && st.country != '' ) {
            st.flag  = st.country;
          }
        });
        this.standings  = rs.items;
        this.loading    = false;
      } else {
        this.loading    = false;
      }
    })
  }

  hidePopovers(except: any = null) {
    this.popover.map((item, index) => {
      if ( ( index != except ) || except == null )
        this.popover[index]  = false;
    })
  }

  loadTeams(){
    this.loading    = true;
    let category    = '';
    if ( this.category.trim() != '' )
      category  = this.category.trim();
    if ( this.category == 'GENERAL')
      category  = 'NULL';

    this.leagueService.standingsTeams(this.league.id, category).then(rs => {
      this.standingsT = rs.items;
      this.loading    = false;
    })
  }

  selectTab(newTab) {
    this.tab = newTab;
  }

  private loadImage(){
    this.loading  = true;
    this.imageService.getImages([{field: 'entity', value: 'league_logo'},{field: 'entity_id', value: this.league.id}])
    .then(response => {
      if ( response.summary.process == false || response.summary.message == 'error' ) {
        this.general.setMessage(response.summary.errorMessage);
      } else {
        if ( response.items.length > 0 && response.items[response.items.length -1].data.src != '' ) {
          this.img_found = true;
          this.img_id = response.items[0].data.id;
          this.imageService.resizeImage(response.items[response.items.length -1].data.src, 180, 180, 'league_logo', 1).then(result => {
            if ( result.summary.process == false || result.summary.message == 'error' ) {
                this.general.setMessage(result.summary.errorMessage);
                this.loading  = false;
            } else {
                this.actual_img = result.summary.full_url;
                this.loading  = false;
            }
          },
          error => {
            this.general.setMessage(<any>error); 
            this.loading = false
          });
        }
      }
      this.loading = false;  
    },
    error => {
        this.general.setMessage(<any>error); 
        this.loading = false
    });
  }

  private loadBanner(){
    this.loading  = true;
    this.imageService.getImages([{field: 'entity', value: 'league_banner'}, {field: 'entity_id', value: this.league.id}])
    .then(banner => {
      if ( banner.summary.process == false || banner.summary.message == 'error' ) {
        this.general.setMessage(banner.summary.errorMessage);
      } else {
        if ( banner.items.length > 0 && banner.items[0].data.src != '' ) {
          this.img_found_full_banner = true;
          this.img_id_full_banner = banner.items[0].data.id;
          this.imageService.resizeImage(banner.items[0].data.src, 1921, null, 'league_banner', 0).then(result => {
            if ( result.summary.process == false || result.summary.message == 'error' ) {
                this.general.setMessage(result.summary.errorMessage);
                this.loading  = false;
            } else {
                this.actual_img_banner = result.summary.full_url;
                this.loading  = false;
            }
          },
          error => {
            this.general.setMessage(<any>error); 
            this.loading = false
          });
        }
      }
      this.loading = false;  
    },
    error => {
        this.loading = false
    });
  }
}
