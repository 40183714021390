<ng-container *ngIf="type == 'badge'">
    <div class="elo align-bottom" *ngIf="driver || team">
        <img *ngIf="driver && driver.flag != null" src="/assets/flags/{{driver.flag}}.svg" class="flag-1 rounded r-1" />
        <img *ngIf="team && team.flag != null" src="/assets/flags/{{team.flag}}.svg" class="flag-1 rounded r-1" />
        <span class="badge badge-license-x r-1" *ngIf="driver && !driver.trs" ngbTooltip="{{'ELO.NO-TRS' | translate}}">X</span>
        <span class="badge badge-license-s r-1" *ngIf="driver && driver.trs && driver.trs > 0 && driver.trs < 5">S</span>
        <span class="badge badge-license-a r-1" *ngIf="driver && driver.trs && driver.trs > 5 && driver.trs < 10">A</span>
        <span class="badge badge-license-b r-1" *ngIf="driver && driver.trs && driver.trs > 10 && driver.trs < 20">B</span>
        <span class="badge badge-license-c r-1" *ngIf="driver && driver.trs && driver.trs > 20 && driver.trs < 25">C</span>
        <span class="badge badge-license-d r-1" *ngIf="driver && driver.trs && driver.trs > 25 && driver.trs < 30">D</span>
        <span class="badge badge-license-e r-1" *ngIf="driver && driver.trs && driver.trs > 30 && driver.trs < 50">E</span>
        <span class="badge badge-license-f r-1" *ngIf="driver && driver.trs && driver.trs > 50 && driver.trs < 100">F</span>
        <span class="badge badge-license-tx r-1" *ngIf="team && !team.trs" ngbTooltip="{{'ELO.NO-TRS-TEAM' | translate}}">X</span>
        <span class="badge badge-license-ts r-1" *ngIf="team && team.trs && team.trs > 0 && team.trs < 5">S</span>
        <span class="badge badge-license-ta r-1" *ngIf="team && team.trs && team.trs > 5 && team.trs < 10">A</span>
        <span class="badge badge-license-tb r-1" *ngIf="team && team.trs && team.trs > 10 && team.trs < 20">B</span>
        <span class="badge badge-license-tc r-1" *ngIf="team && team.trs && team.trs > 20 && team.trs < 25">C</span>
        <span class="badge badge-license-td r-1" *ngIf="team && team.trs && team.trs > 25 && team.trs < 30">D</span>
        <span class="badge badge-license-te r-1" *ngIf="team && team.trs && team.trs > 30 && team.trs < 50">E</span>
        <span class="badge badge-license-tf r-1" *ngIf="team && team.trs && team.trs > 50 && team.trs < 100">F</span>
        <span class="badge badge-driver" *ngIf="driver && driver.count_races > 4">{{driver.elo}}</span>
        <span class="badge badge-driver" *ngIf="driver && driver.count_races <= 4" ngbTooltip="{{'ELO.NEW' | translate}}">&nbsp;-&nbsp;</span>
        <span class="badge badge-team" *ngIf="team && team.count_races > 4">{{team.elo}}</span>
        <span class="badge badge-team" *ngIf="team && team.count_races <= 4" ngbTooltip="{{'ELO.NEW-TEAM' | translate}}">&nbsp;-&nbsp;</span>
    </div>
</ng-container>
<ng-container *ngIf="type == 'text' && driver && driver.count_races > 4">
    <span class="badge badge-license-x {{class_left}}" *ngIf="driver && !driver.trs" ngbTooltip="{{'ELO.NO-TRS' | translate}}">X</span>
    <span class="badge badge-license-s {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 0 && driver.trs < 5">S</span>
    <span class="badge badge-license-a {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 5 && driver.trs < 10">A</span>
    <span class="badge badge-license-b {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 10 && driver.trs < 20">B</span>
    <span class="badge badge-license-c {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 20 && driver.trs < 25">C</span>
    <span class="badge badge-license-d {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 25 && driver.trs < 30">D</span>
    <span class="badge badge-license-e {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 30 && driver.trs < 50">E</span>
    <span class="badge badge-license-f {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 50 && driver.trs < 100">F</span>
    {{driver.elo}}
</ng-container>
<ng-container *ngIf="type == 'text' && driver && driver.elo == '0'">
    <span class="badge badge-license-x {{class_left}}" *ngIf="driver && !driver.trs" ngbTooltip="{{'ELO.NO-TRS' | translate}}">X</span>
    <span class="badge badge-license-s {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 0 && driver.trs < 5">S</span>
    <span class="badge badge-license-a {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 5 && driver.trs < 10">A</span>
    <span class="badge badge-license-b {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 10 && driver.trs < 20">B</span>
    <span class="badge badge-license-c {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 20 && driver.trs < 25">C</span>
    <span class="badge badge-license-d {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 25 && driver.trs < 30">D</span>
    <span class="badge badge-license-e {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 30 && driver.trs < 50">E</span>
    <span class="badge badge-license-f {{class_left}}" *ngIf="driver && driver.trs && driver.trs > 50 && driver.trs < 100">F</span>
    <span ngbTooltip="{{'ELO.NEW' | translate}}">&nbsp;-&nbsp;</span>
</ng-container>
<ng-container *ngIf="type == 'text' && team && team.count_races > 4">
    <span class="badge badge-license-tx {{class_left}}" *ngIf="team && !team.trs" ngbTooltip="{{'ELO.NO-TRS-TEAM' | translate}}">X</span>
    <span class="badge badge-license-ts {{class_left}}" *ngIf="team && team.trs && team.trs > 0 && team.trs < 5">S</span>
    <span class="badge badge-license-ta {{class_left}}" *ngIf="team && team.trs && team.trs > 5 && team.trs < 10">A</span>
    <span class="badge badge-license-tb {{class_left}}" *ngIf="team && team.trs && team.trs > 10 && team.trs < 20">B</span>
    <span class="badge badge-license-tc {{class_left}}" *ngIf="team && team.trs && team.trs > 20 && team.trs < 25">C</span>
    <span class="badge badge-license-td {{class_left}}" *ngIf="team && team.trs && team.trs > 25 && team.trs < 30">D</span>
    <span class="badge badge-license-te {{class_left}}" *ngIf="team && team.trs && team.trs > 30 && team.trs < 50">E</span>
    <span class="badge badge-license-tf {{class_left}}" *ngIf="team && team.trs && team.trs > 50 && team.trs < 100">F</span>
    {{team.elo}}
</ng-container>
<ng-container *ngIf="type == 'text' && team && team.elo == '0'">
    <span class="badge badge-license-tx {{class_left}}" *ngIf="team && !team.trs" ngbTooltip="{{'ELO.NO-TRS-TEAM' | translate}}">X</span>
    <span class="badge badge-license-ts {{class_left}}" *ngIf="team && team.trs && team.trs > 0 && team.trs < 5">S</span>
    <span class="badge badge-license-ta {{class_left}}" *ngIf="team && team.trs && team.trs > 5 && team.trs < 10">A</span>
    <span class="badge badge-license-tb {{class_left}}" *ngIf="team && team.trs && team.trs > 10 && team.trs < 20">B</span>
    <span class="badge badge-license-tc {{class_left}}" *ngIf="team && team.trs && team.trs > 20 && team.trs < 25">C</span>
    <span class="badge badge-license-td {{class_left}}" *ngIf="team && team.trs && team.trs > 25 && team.trs < 30">D</span>
    <span class="badge badge-license-te {{class_left}}" *ngIf="team && team.trs && team.trs > 30 && team.trs < 50">E</span>
    <span class="badge badge-license-tf {{class_left}}" *ngIf="team && team.trs && team.trs > 50 && team.trs < 100">F</span>
    <span ngbTooltip="{{'ELO.NEW-TEAM' | translate}}">&nbsp;-&nbsp;</span>
</ng-container>

<!-- 50.01 a 100 F
30.01 a 50.00 E
25.01 a 30.00 D
23.01 a 25.00 C
13.01 a 23.00 B
5.01 a 13.00 A
0.01 a 5.00 S
0 X -->