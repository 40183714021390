<div class="row mt-5" *ngIf="user">
    <div class="col-12 col-md-2 text-center text-md-left upload-wrapper uploaded-wrapper">
        <div class="actual-img actual-img-big text-uppercase"
            *ngIf="actual_img == '' && !img_found; else with_image">
            {{user.nickname | slice: 0:1}}</div>
        <ng-template #with_image>
            <div class="uploaded-actual-img uploaded-img text-center">
                <!-- <a class="btn btn-primary btn-xs" (click)="deleteAvatar()"
                    ngbTooltip="{{ 'MY-ACCOUNT.DELETE_AVATAR' | translate }}"
                    title="{{ 'MY-ACCOUNT.DELETE_AVATAR' | translate }}">
                    <span class="fa fa-times white"></span>
                </a> -->
                <img src="{{actual_img}}" alt=""
                    class="img-responsive border img-fluid rounded-circle">
            </div>
        </ng-template>
    </div>
    <div class="col-12 col-md-10 text-center text-md-left">
        <h2>{{user.name}}</h2>
        <h4><img src="/assets/flags/{{svgFlag}}.svg" class="flag-1" /> {{user.nickname}}</h4>
        <p *ngIf="teams.length > 0">{{'DRIVER.TEAMS' | translate}}: <a *ngFor="let team of teams" routerLink="/teams/view/{{team.data.Teams.url}}"><span class="badge badge-primary">{{team.data.Teams.name}}</span>&nbsp;</a></p>
        <p *ngIf="teams.length == 0">{{'DRIVER.TEAMS' | translate}}: <small><em>({{'DRIVER.NO_TEAM' | translate}})</em></small></p>
        <a class="btn btn-outline-primary full-width" routerLink="/drivers/view/{{user.url}}">{{'MY-ACCOUNT.VIEW-MY-PUBLIC-PAGE' | translate}}</a>
    </div>
</div>