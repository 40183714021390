import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'site-footer',
  templateUrl: './footer.component.html',
  providers: [NgbDropdownConfig]
})

export class SiteFooterComponent implements OnInit {

  ngOnInit(): void {
    
  }
  

}
