<nav id="" aria-label="navigation">
    <ul class="pagination pagination-rounded-separated mt-1">
        <li class="page-item">
            <a class="page-link btn" [class.disabled]="!first" (click)="pageCommand('first')">{{ 'PAGINATION.FIRST' | translate }}</a>
        </li>
        <li class="page-item">
            <a class="page-link btn" [class.disabled]="!prev" (click)="pageCommand('prev')" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">{{ 'PAGINATION.PREVIOUS' | translate }}</span>
            </a>
        </li>
        <ng-container *ngIf="show_numbers == true">
            <li class="page-item" *ngFor="let page_item of pages; let i = index;" [class.active]="(actualPage + 1 == page_item.name)">
                <a class="page-link btn" *ngIf="i<number_of_pages" (click)="pageChange(page_item.name)">
                    {{page_item.name}}
                </a>
            </li>
        </ng-container>
        <li class="page-item">
            <a class="page-link btn" [class.disabled]="!next" (click)="pageCommand('next')" arial-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">{{ 'PAGINATION.NEXT' | translate }}</span>
            </a>
        </li>
        <li class="page-item">
            <a class="page-link btn" [class.disabled]="!last" (click)="pageCommand('last')">{{ 'PAGINATION.LAST' | translate }}</a>
        </li>
    </ul>
</nav>
<p class="mt-1 custom-pagination-desc" *ngIf="total > 1">{{ 'PAGINATION.RECORDS-FOUND' | translate:{total: total, page: (page + 1), number_of_pages: number_of_pages} }}</p>
<p class="mt-1 custom-pagination-desc" *ngIf="total == 1">{{ 'PAGINATION.RECORD-FOUND' | translate:{total: total, page: (page + 1), number_of_pages: number_of_pages} }}</p>