import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'changedRating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class ChangedRatingComponent implements OnInit {
    @Input()
        rating: any;
    
    constructor(
    ) { }

    ngOnInit() {
    }

}