import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

    @Input()
        steps: any;
    @Input()
        step: any = 1;
    @Input()
        showNames: boolean = false;
    
    constructor(
        private translate: TranslateService,
    ) { }

    ngOnInit() {
    }

}