<div *ngIf="loading" class="loading-background">
	<div class="gooey">
		<span class="dot"></span>
		<div class="dots">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
	<p>{{ 'LOADING.LOADING' | translate }}</p>
</div>