import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RestService {
  //PROD
  private serviceURL  = 'https://api.theracersesports.com/';
  private _tokenUrl   = 'https://api.theracersesports.com/Portal/token?key=b2204a907fa9f08b5092e860211912d9'; 

  public token: string;                                                               
  private argts: any;                     

  constructor(
    protected _http: HttpClient
  ){
    localStorage.setItem('api_url', this.serviceURL);    
  } 

  get(url: string, args = {}) {
    return this._http.get(url, args).toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getApi(url: string, args = {}, outer: boolean = false) {
    let urlFinal = this.serviceURL + url;   
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      if (outer)
        urlFinal = url;
      return this._http.get(urlFinal, args).toPromise()
        .then(response => response)
        .catch(this.handleError);
    });
  }

  postApi(url: string, body: any, args = {}) {
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      return this._http.post(this.serviceURL + url, body, args).toPromise()
        .then(response => response)
        .catch(this.handleError);
    });
  }

  patchApi(url: string, body: any, args = {}) {
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      return this._http.patch(this.serviceURL + url, body, args).toPromise()
        .then(response => response)
        .catch(this.handleError);
    });
  }

  putApi(url: string, body: any, args = {}) {
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      return this._http.put(this.serviceURL + url, body, args).toPromise()
        .then(response => response)
        .catch(this.handleError);
    });
  }

  deleteApi(url: string, args = {}) {
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      return this._http.delete(this.serviceURL + url, args).toPromise()
        .then(response => response)
        .catch(this.handleError);
    });
  }

  getToken() {
    let newToken: boolean = true;
    let storedToken: any = localStorage.getItem('token');
    if (storedToken && storedToken != 'undefined') {
      storedToken = JSON.parse(storedToken);
      let d = new Date();
      let month = d.getMonth() + 1;
      let monthStr = '';
      if (month < 10) {
        monthStr = '0' + month.toString();
      } else {
        monthStr = month.toString();
      }
      let day = d.getDate();
      let dayStr = '';
      if (day < 10) {
        dayStr = '0' + day.toString();
      } else {
        dayStr = day.toString();
      }
      let hour = d.getHours();
      let hourStr = '';
      if (hour < 10) {
        hourStr = '0' + hour.toString();
      } else {
        hourStr = hour.toString();
      }
      let minute = d.getMinutes();
      let minuteStr = '';
      if (minute < 10) {
        minuteStr = '0' + minute.toString();
      } else {
        minuteStr = minute.toString();
      }
      let second = d.getSeconds();
      let secondStr = '';
      if (second < 10) {
        secondStr = '0' + second.toString();
      } else {
        secondStr = second.toString();
      }
      let now = d.getFullYear() + "-" + monthStr + '-' + dayStr + "T" + hourStr + ":" + minuteStr + ":" + secondStr + '-02:00';
      if (storedToken.expires > now) {
        newToken = false;
        return Promise.resolve(storedToken.token);
      } else {
        newToken = true;
      }
    }
    if (newToken) {
      localStorage.removeItem('token');
      return this._http.get(this._tokenUrl).toPromise()
        .then((response: any) => {
          localStorage.setItem("token", JSON.stringify(response.data));
          return response.data.token;
        })
        .catch(this.handleError);
    }
  }

  getAuthorizationHeader(needAuthorization: boolean = false) {

    if (needAuthorization) {
      let tokenUser = localStorage.getItem('tokenUser');
      if (tokenUser) {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + tokenUser });
        return headers;
      }
    }
    let headers = new HttpHeaders();
    return headers;

  }

  protected handleError(err: HttpErrorResponse) {

    // TODO: Colocar a lógica para tratar as mensagens de erro quando retornar 200
    let errorMessage = '';
    if (err.error instanceof Error) {
      errorMessage = `Ocorreu um erro: ${err.error.message}`;
    } else {
      errorMessage = `Servidor retornou o erro: ${err.status}, mensagem: ${err.message}`;
    }
    return Promise.reject({errorMessage: errorMessage, errorStatus: err.status});
  }

  protected toUpperCase(obj) {
    let no_change = ['country', 'reference', 'state'];
    for(var i in obj) {
      if ( no_change.indexOf(i) == -1 ) {
        let val =  obj[i];
        if(val != undefined || val != 'undefined'){
          if(typeof val == 'string')
            obj[i] = val.toUpperCase(); 
        }
      }
    }    
    return obj;
  }
}