import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "my-account-menu",
  templateUrl: "./my-account-menu.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MyAccountMenuComponent implements OnInit {
  
  link: string = 'my-account';

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    let actualLink  = this.router.url.replace('/', '');
    if ( localStorage.getItem('menu_internal_user') != null ) {
      this.link = JSON.parse(localStorage.getItem('menu_internal_user'));
    } else {
      this.setItem('my-account');
    }
    if ( actualLink == 'my-account' ) {
      this.setItem('my-account');
    }
    if ( actualLink.substring(0,10) == 'cases/view' ) {
      this.link = 'cases';
      localStorage.setItem('menu_internal_user', JSON.stringify(this.link));
    }
  }

  setItem(item) {
    this.link = item;
    localStorage.setItem('menu_internal_user', JSON.stringify(this.link));
    this.router.navigate(['/' + item]);
  }
}
