import { Component, OnInit  } from '@angular/core';

@Component({
  selector: 'app-site-embed',
  templateUrl: './site-embed.component.html',
  styleUrls: ['./site-embed.component.scss']
})
export class SiteEmbedComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }
}
