import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notifications.service';
import { ImageService } from 'src/app/services/image.service';
import { UserService } from 'src/app/services/user.service';
import { OrganizerService } from 'src/app/services/organizer.service';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'site-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [NgbDropdownConfig]
})

export class TopbarComponent implements OnInit {
  public sidebarOpened = false;
  auth: any;
  notifications: any = [];
  showNotifications: boolean = false;

  constructor(
    private imageService: ImageService,
    private notificationService: NotificationService,
    private userService: UserService,
    private organizerService: OrganizerService,
    private teamService: TeamService
    ) { 
      
    }

  ngOnInit(): void {
    this.auth =  JSON.parse(localStorage.getItem('auth'));
    document.querySelector('#navbarNavAltMarkup').classList.add('d-none');
    document.querySelector('#navbarNavAltMarkup1').classList.add('d-none');
    this.loadNotifications();
  }
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    this.showNotifications  = false;
    if (this.sidebarOpened) {
      document.querySelector('#navbarNavAltMarkup').classList.add('d-flex');
      document.querySelector('#navbarNavAltMarkup1').classList.add('d-flex');
    }
    else {
      document.querySelector('#navbarNavAltMarkup').classList.remove('d-flex');
      document.querySelector('#navbarNavAltMarkup1').classList.remove('d-flex');
    }
  }

  setLang(language: string = 'en') {
    localStorage.setItem('language_theracers', language);
    window.location.reload();
  }

  loadNotifications() {
    this.notifications  = [];
    if ( this.auth && this.auth.user && this.auth.user.id ) {
      this.notificationService.getList(this.auth.user.id).then((rs: any) => {
        if ( rs.data && rs.data.length > 0 )
          this.notifications  = rs.data;
          this.notifications.map(item => {
            item.vars = JSON.parse(item.vars);
          });
      })
    }
  }

  markAsRead(index) {
    let save  = this.notifications[index];
    let now   = new Date();
    let date  = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
    this.notificationService.updateField(save.id, 'read_at', date).then(rs => {
      this.notifications[index].reat_at  = '1';
    });
  }

  markAllRead(){
    if ( this.notifications.length > 0 ) {
      this.notifications.map(item => {
        let save  = item;
        let now   = new Date();
        let date  = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        this.notificationService.updateField(save.id, 'read_at', date).then(rs => {
          item.reat_at  = '1';
        });
      });
      this.loadNotifications();
    }
  }

}
