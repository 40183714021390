<app-loading [loading]="loading"></app-loading>
<div class="container">
    <div class="row mt-5">
        <div class="col-12 col-md-4 offset-md-1">
            <h3>{{ 'LOGIN.WELCOME_AGAIN' | translate }}</h3>
            <p class="text-center text-md-left">{{ 'LOGIN.DESC' | translate }}</p>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <label>{{ 'LOGIN.EMAIL' | translate }}</label>
                            <input type="text" class="form-control" (keyup.enter)="signIn()" [(ngModel)]="login.email" id="login1" name="login1" placeholder="{{ 'LOGIN.EMAIL-LOGIN' | translate }}" required autofocus>
                        </div>
                        <div class="col-12 mt-3">
                            <label>{{ 'LOGIN.PASSWORD' | translate }}</label>
                            <input type="password" [disabled]="loading" (keyup.enter)="signIn()" [(ngModel)]="login.password" id="password1" name="password1" class="form-control" #password placeholder="{{ 'LOGIN.PASSWORD' | translate }}" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3">
                            <input type="submit" [disabled]="loading" (click)="signIn()" class="btn btn-primary" value="{{ 'LOGIN.LOGIN-BUTTON' | translate }}">
                            <!-- <img src="./assets/images/steam.png" /> -->
                            <span class="mt-2 ml-1"><a routerLink="/forgot"><small>{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</small></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
            <h3>{{ 'REGISTER.FIRST_ACCESS' | translate }}</h3>
            <p class="text-center text-md-left">{{ 'REGISTER.DESC' | translate }}</p>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <label class="col-4">{{ 'REGISTER.NAME' | translate }}</label>
                        <div class="col-8">
                            <input type="text" class="form-control" (keyup.enter)="save()" 
                            [disabled]="loading" [(ngModel)]="register.name" id="name" name="name" 
                            placeholder="{{ 'REGISTER.NAME' | translate }}" required autofocus
                            [ngClass]="validation.fields.indexOf('register.name') > -1 ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-4">{{ 'REGISTER.E-MAIL' | translate }}</label>
                        <div class="col-8">
                            <input type="text" class="form-control" (keyup.enter)="save()" 
                            [disabled]="loading" [(ngModel)]="register.email" id="login" name="login" 
                            placeholder="{{ 'REGISTER.E-MAIL' | translate }}" required autofocus
                            [ngClass]="validation.fields.indexOf('register.email') > -1 ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-4">{{ 'LOGIN.PASSWORD' | translate }}</label>
                        <div class="col-8">
                            <input type="password" [disabled]="loading" (keyup.enter)="save()" 
                            [(ngModel)]="register.password" id="password" name="password" class="form-control" #password 
                            placeholder="{{ 'REGISTER.PASSWORD' | translate }}" required
                            [ngClass]="validation.fields.indexOf('register.password') > -1 ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-4">{{ 'MY-ACCOUNT.CELLPHONE' | translate }}</label>
                        <div class="col-8 input-group">
                            <input type="text" ng2TelInput [textMask]="{mask: maskPhone, placeholderChar: ' '}" [(ngModel)]="register.cellphone" 
                            [ng2TelInputOptions]="{initialCountry: country_ref}" (keyup.enter)="save()" 
                            (intlTelInputObject)="telInputObject($event)"
                            [disabled]="loading" name="phone" class="form-control" 
                            [ngClass]="validation.fields.indexOf('register.cellphone') > -1 ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                            <h5>
                                {{ 'MY-ACCOUNT.OPTIONAL_DATA' | translate }}
                                <small>{{ 'MY-ACCOUNT.OPTIONAL_DATA_SMALL' | translate }}</small>
                            </h5>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-4">{{ 'MY-ACCOUNT.STEAM' | translate }}</label>
                        <div class="col-8">
                            <input type="text" [textMask]="{mask: maskSteam, placeholderChar: ' '}" class="form-control" (keyup.enter)="save()" [disabled]="loading" [(ngModel)]="register.steamId" id="steamId" name="steamId" placeholder="{{ 'MY-ACCOUNT.STEAM' | translate }}" autofocus>
                            <u><small><a (click)="showSteam = !showSteam">{{'MY-ACCOUNT.HELP-STEAM' | translate}}</a></small></u>
                        </div>
                        <div class="col-12 mt-1" *ngIf="showSteam">
                            <div class="card bg-light">
                                <div class="card-body" [innerHTML]="'MY-ACCOUNT.STEAM-DESC' | translate"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3">
                            <input type="submit" [disabled]="loading" (click)="save()" class="btn btn-primary" value="{{ 'LOGIN.LOGIN-BUTTON' | translate }}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-10 offset-md-1 mb-3 mt-2">
            <div class="card">
                <div class="card-body bg-light">
                    <small>
                        <em>- {{'LOGIN.P1' | translate}}<br />
                            - {{'LOGIN.P2' | translate}}<br />
                            - {{'LOGIN.P3' | translate}} <a routerLink="/pages/privacy">{{'LOGIN.P4' | translate}}</a>
                        </em>
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>