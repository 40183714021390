import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'elo',
    templateUrl: './elo.component.html',
    styleUrls: ['./elo.component.scss']
})
export class EloComponent implements OnInit {
    @Input()
        driver: any;
    @Input()
        team: any;
    @Input()
        class_left: string = ''
    @Input()
        class_right: string = 'mr-1'
    @Input()
        type: string = 'badge'
    
    constructor(
    ) { }

    ngOnInit() {
    }

}