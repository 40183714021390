import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { VariableService } from './services/variable.service';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService} from 'ng-connection-service'

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private variablesService: VariableService,
    private connectionService: ConnectionService
  ){
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
        }
    //   const navEndEvent$ = router.events.pipe(
    //       filter(e => e instanceof NavigationEnd)
    //     );
    //     navEndEvent$.subscribe((e: NavigationEnd) => {
    //       gtag('config', 'UA-173701499-1', {'page_path':e.urlAfterRedirects});
    });        
  }

  new_password: string = '';
  title: string = '';
  config: any = [];
  loading: boolean = false;
  invalid: boolean = false;
  errorMessage: any = [];
  watch: any;
  offline_mode: boolean = false
  version: string = '1.2.0';


  ngOnInit() {
      //set session watcher
      this.internetVerifier();
      this.checkversion();
  }

  checkversion(){
      if ( localStorage.getItem('version') != null ) {
          let version = JSON.parse(localStorage.getItem('version'));
          if ( typeof(version) != undefined && version != this.version ) {
              localStorage.setItem('version', JSON.stringify(this.version));
              window.location.reload();
          }
      } else {
          localStorage.setItem('version', JSON.stringify(this.version));
          window.location.reload();
      }
  }

  internetVerifier(){
      this.connectionService.monitor().subscribe(isConnected => {
          if(!isConnected){
              this.offline_mode = true
          }else{
              this.offline_mode = false
          }
        })
  }

  ngAfterViewInit() {
      this.loading    = true;
      this.variablesService.getVariables({}).then(result => {
          if ( !result.summary.total || result.summary.total == 0 ) {
              this.router.navigate(['/logout']);
          } else {
              this.config = {}
              for (let i = 0; result.items[i]; i++) {
                  this.config[result.items[i].data.name]  = result.items[i].data.value;
              }
              localStorage.setItem('config', JSON.stringify(this.config));
              this.loading    = false;
              
              this.title  = this.config['title'];
              let language = localStorage.getItem('language_theracers');
              if ( language == '' || language == null ) {
                  if ( this.config['default_language'] )
                      language = this.config['default_language'];
                  else
                      language = 'en';
              }
              this.translate.use(language);

              // this.router.events
              // .filter((event) => event instanceof NavigationEnd)
              // .map(() => this.activatedRoute)
              // .map((route) => {
              //     while (route.firstChild) route = route.firstChild;
              //         return route;
              //     })
              //     .filter((route) => route.outlet === 'primary')
              //     .mergeMap((route) => route.data)
              //     .subscribe((event) => {
              //         if ( event && event['title'] && event['title'] != '' ) {
              //             this.translate.get(event['title']).subscribe(value => { 
              //                 this.titleService.setTitle(this.title + ' - ' + value);
              //             });
              //         }
              //     });
          }
      });
  }
}
