import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/toPromise';

import { Auth } from '../model/auth.model';
import { User } from '../model/user.model';

import { PermissionService } from './permission.service';

import { RestService } from './rest.service';
import { ImageService } from './image.service';
import { GeneralComponent } from '../_utils/general/general.component';
import { UserService } from './user.service';


@Injectable()
export class AuthMainService extends RestService {

	private loggedIn = new BehaviorSubject<boolean>(false); // {1}
	auth: Auth;
	authClass : {
		class: any,
		isLogged: boolean
	} = {
		class: [],
		isLogged: false
	};
	private _loginUrl = 'Users/login';
	private _loginPUrl = 'Users/Plogin';
	private _preLoginUrl = 'Users/preLogin';
	private _loginProcessUrl = 'Classes/login';

	get isLoggedIn() {
		return this.loggedIn.asObservable(); // {2}
	}

	constructor(
		protected _http: HttpClient,
		private permissionService: PermissionService,
		private imageService: ImageService,
		private general: GeneralComponent,
		private userService: UserService
		) {
		super(_http);
		if(this.isLogged()) {
		  this.auth = this.getUser();
		  this.loggedIn.next(true);
		}
	}

	async setClass(classe){
		let auth 	= new Auth();
		let auth1	= <Auth>JSON.parse(localStorage.getItem('auth'));

		auth.user = classe;
		auth.isLogged = true;
		Object.assign(auth, auth1);

		localStorage.setItem('auth', JSON.stringify(auth));
		this.loggedIn.next(true);
		return true;
	}

	async setUser(user: User, permissions: any) {
		localStorage.removeItem('avatar');
		let auth 	= new Auth();
		let auth1	= <Auth>JSON.parse(localStorage.getItem('auth'));
		if ( user.tokenUser && user.tokenUser != '' ) {
			localStorage.setItem('tokenUser', user.tokenUser);
		}
		auth.user = user;
		auth.isLogged = true;
		Object.assign(auth, auth1);
		if ( permissions.length > 0 ) {
			permissions.push('');
			permissions.push('/');
			permissions.push('login');
			// localStorage.setItem('sessionHash', user.sessionHash);
			if ( auth.user.root == 'N' ) {
				//remove permissions with root access
				return await this.permissionService.getPermissions({root: 'IN|Y'})
					.then(response => {
						if ( response.summary.process ) {
							for ( let i = 0; response.items[i]; i++ ) {
								let indexRemove	= permissions.indexOf(response.items[i].data.action);
								delete permissions[indexRemove];
							}
							auth.permissions 	= permissions;
							localStorage.setItem('auth', JSON.stringify(auth));
							this.getAvatar(user.id);
							this.loggedIn.next(true);
							return true;
						} else {
							//erro nas permissões obriga novo login
							this.logout();
							return false;
						}
					});
			} else {
				auth.permissions 	= permissions;
				localStorage.setItem('auth', JSON.stringify(auth));
				this.getAvatar(user.id);
				this.loggedIn.next(true);
				return true;
			}
		} else {
			this.loggedIn.next(false);
			return false;
		}
	}

	// public getAvatar(user_id): any {
	// 	return this.imageService.getImages({entity: 'user', entity_id: user_id})
	// 		.then(response => {
	// 			if ( response.summary.process == false || response.summary.message == 'error' ) {
	// 				localStorage.removeItem('avatar');
	// 				return true;
	// 			} else {
	// 				if ( response.items.length > 0 && response.items[0].data.src != '' ) {
	// 					this.imageService.resizeImage(response.items[0].data.src, 150, 150, 'user', true).then(result => {
	// 						if ( result.summary.process == false || result.summary.message == 'error' ) {
	// 							localStorage.removeItem('avatar');
	// 							return true;
	// 						} else {
	// 							localStorage.setItem('avatar', result.summary.full_url);
	// 							return true;
	// 						}
	// 					});
	// 				}
	// 			}
	// 		});
	// }

	public getAvatar(user_id): any {
		return this.imageService.getImages({entity: 'user', entity_id: user_id})
			.then(response => {
				if ( response.summary.process == false || response.summary.message == 'error' ) {
					localStorage.removeItem('avatar');
					return true;
				} else {
					if ( response.items.length > 0 && response.items[response.items.length - 1].data.src != '' ) {
						return this.imageService.resizeImage(response.items[response.items.length - 1].data.src, 150, 150, 'user', 1).then(result => {
							if ( result.summary.process == false || result.summary.message == 'error' ) {
								localStorage.removeItem('avatar');
								return true;
							} else {
								localStorage.setItem('avatar', result.summary.full_url);
								return true;
							}
						});
					}
				}
			});
	}

	public getUser() : Auth{
		let auth : string|Auth = localStorage.getItem('auth');
		if(auth){
			auth = <Auth>JSON.parse(auth);
		}else{
			auth = new Auth();
		}
		return auth;
	}

	public isLogged() : boolean{
		return this.getUser().isLogged;
	}

	public unsetUser(){
		// kill user session

		let auth1	= <Auth>JSON.parse(localStorage.getItem('auth'));
		if ( auth1 && auth1.user && auth1.user.activeSession != 0 )
			this.userService.killSession(auth1.user.activeSession);

		localStorage.removeItem('auth');
		localStorage.removeItem('token');
		localStorage.removeItem('preUser');
		localStorage.removeItem('tokenUser');
		localStorage.removeItem('tokenUserExpires');
		localStorage.removeItem('sessionHash');
		localStorage.removeItem('avatar');
		localStorage.removeItem('parallel-login');
		this.loggedIn.next(false);
	}

	public login(user: string, password: string) : any {
		let data		= JSON.stringify({login: user, password: password});
		let response	= this.postApi(this._loginUrl, data);
		return response;
	}

	public personateLogin(userId: string, email: string) : any {
		let data		= JSON.stringify({login: email, id: userId});
		let response	= this.postApi(this._loginPUrl, data);
		return response;
	}

	public preLogin(user: string, password: string) : any {
		let data		= JSON.stringify({login: user, password: password});
		let response	= this.postApi(this._preLoginUrl, data);
		return response;
	}

	public loginProcess(user: string, password: string) : any {
		let data		= JSON.stringify({login: user, password: password});
		let response	= this.postApi(this._loginProcessUrl, data);
		return response;
	}

	public logout(){
		this.unsetUser();
	}

}
