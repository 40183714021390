import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends RestService {
  private _url = 'Notifications';
  private _urlList = 'Notifications/list';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  updateNotification(Notification: any): any {
    let data = JSON.stringify(Notification);
    return this.putApi(this._url + '/' + Notification.id, data);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data); 
  }

  getNotifications(filter_data: any, contain_string: string = '', order_string: any = '-Notifications.created', page: number = 0, fields: string = '', limit: number = 20, report:boolean = false, groupData: any = [], operationsData: any = [], unlimited = 0): any {
    let qs: string = '';
    let group: string = '';
    let operations: string = '';
    let paging: string = '';
    let order = order_string

    if ( filter_data != null && filter_data.length > 0 ) {
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }
    
    if(groupData && groupData.length > 0){
      groupData.map(item => {        
           group += item.field + ';'        
      })
    }
    if(operationsData && operationsData.length > 0){
      operationsData.map(item => {        
           operations += item.operation + '|' + item.field + ';'        
      })
    }
    if(Array.isArray(order)){
      const orderArray = order
      order = ''
      orderArray.map(item => order += `${item.order}${item.field};`)
    }
    
    // return
    if(report == true)
       qs = filter_data;

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order, contains: contain_string, fields: fields, group: group, operations: operations, unlimited: unlimited } });
  }

  getList(user_id): any {
    return this.getApi(this._urlList, { params: { user_id: user_id } });
  }
}