import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {

    @Input()
	    total: any;
    @Input()
        show_numbers: boolean = true;
    @Input()
        page: string;
    @Input()
	    number_of_pages: number;
    @Output() 
        newPage = new EventEmitter();
 
    pages: any = [];

    loading: boolean = false;

    first: boolean = false;
    prev: boolean = false;
    next: boolean = false;
    last: boolean = false;
    
    actualPage  = 0;

    constructor(
        private _route: ActivatedRoute,
    ){}

    ngOnInit() {
        this._route.params
            .subscribe(params => {
            this.loadPage();
        });
    }

    ngOnChanges() {
        this.loadPage();
    }

    loadPage(){
        this.loading    = true;
        delete this.pages;
        this.pages      = [];
        if ( this.number_of_pages == undefined )  
            this.number_of_pages    = 1;
        
        let i = this.actualPage - 10;
        if ( i < 1 )
            i   = 1;
        let max = i + 20;
        if ( max > this.number_of_pages )
            max = this.number_of_pages;
        for ( i; i <= max; i++ ) {
            let item    = {name: i}
            this.pages.push(item);
        }
        if ( this.number_of_pages > 1 ) {
            if ( this.actualPage == 0 && ( this.number_of_pages - 1 ) > 0 ) {
                this.first  = false;
                this.prev   = false;
                this.next   = true;
                this.last   = true;
            } else if ( this.actualPage == ( this.number_of_pages - 1 ) ) {
                this.first  = true;
                this.prev   = true;
                this.next   = false;
                this.last   = false;
            } else if ( this.actualPage <= ( this.number_of_pages - 1 ) ) {
                this.first  = true;
                this.prev   = true;
                this.next   = true;
                this.last   = true;
            } else {
                this.first  = true;
                this.prev   = true;
                this.next   = false;
                this.last   = false;
            }
        } else {
            this.first  = false;
            this.prev   = false;
            this.next   = false;
            this.last   = false;
        }
        this.loading    = false;
    }

    pageChange(page){
        page            = page -1;
        this.actualPage = page;
        this.newPage.emit(page);
        this.loadPage();
    }

    pageCommand(action = 'first'){
        let set_page    = this.actualPage;

        if ( action == 'first' ) {
            set_page    = 0;
        } else if ( action == 'prev' ) {
            set_page    = set_page - 1;
        } else if ( action == 'next' ) {
            set_page    = set_page + 1;
        } else if ( action == 'last' ) {
            set_page    = this.number_of_pages - 1;
        }
        if ( set_page > this.number_of_pages ) {
            set_page    = this.number_of_pages - 1;
        }
        if ( set_page < 0 ) {
            set_page    = 0;
        }

        this.actualPage = set_page;
        this.newPage.emit(set_page);
        this.loadPage();
    }

}