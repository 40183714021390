import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

    @Input()
    	items: any  = [];

    constructor(
        private route: ActivatedRoute,
    ){}
 
    ngOnInit() { this.load() }

    ngOnChanges() { this.load() }

    load(){
        this.items = this.route.snapshot.data['breadcrumbs'];       
    }

}