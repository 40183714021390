import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { RestService } from './rest.service';


@Injectable()
export class PermissionService extends RestService {


    private _url = 'Permissions';
    
	constructor(protected _http: HttpClient) {
		super(_http);
	} 

    insertPermission(Permission: any) : any {
        let data    = JSON.stringify(Permission);
        return this.postApi(this._url, data);
    }
    
    updatePermission(Permission: any) : any {
        let data    = JSON.stringify(Permission);
        return this.putApi(this._url + '/' + Permission.id, data);
    }

    deleteItem(id: number): any {
      return this.deleteApi(this._url + '/' + id);
    }
  
    
    getPermissions(filter_data: any, contains: string = '', order_string: string = '+Permissions.name', page: number = 0, fields: string = '', limit: number = 2000): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'Permissions.id|' + filter_data.id + ';';
        }
        if ( filter_data.idIN && filter_data.idIN != null ) {
            qs   += 'Permissions.id|IN|' + filter_data.idIN + ';';
        }
        if ( filter_data.name && filter_data.name != '' ) {
            qs   += 'Permissions.name|like|' + filter_data.name + ';';
        }
        if ( filter_data.status && filter_data.status != '' ) {
            qs   += 'Permissions.status|' + filter_data.status + ';';
        }
        if ( filter_data.root && filter_data.root != '' ) {
            qs   += 'Permissions.root|' + filter_data.root + ';';
        }
        if ( filter_data.module_id && filter_data.module_id != '' ) {
            qs   += 'Permissions.module_id|' + filter_data.module_id + ';';
        }
        if ( filter_data.menu && filter_data.menu != '' ) {
            qs   += 'Permissions.menu|' + filter_data.menu + ';';
        }
        
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    
    getPermission(id: number): any {
        return this.getApi(this._url, {params: {query: "id|" + id}});
    }
    

}
